import axios from "axios";

export const globalApiCalling = async (setLoading, method, url, data) => {
  try {
    setLoading(true);
    const bearerToken = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    };
    const response = await axios({
      method,
      url,
      headers,
      data,
    });
    if (response.status == 200) {
      // console.log(response.status);
    }
    setLoading(false);
    return response;
  } catch (err) {
    // console.log({ err });
    return err;
  }
};

// export const makeAuthorizedApiRequestAxios = async (
//   method,
//   url,
//   data = null,
//   noContentTypeHeader
// ) => {
//   const bearerToken = localStorage.getItem("token");
//   const headers = {
//     "Content-Type": !noContentTypeHeader && "application/json",
//     Authorization: `Bearer ${bearerToken}`,
//   };

//   try {
//     const response = await axios({
//       method,
//       url,
//       headers,
//       data,
//     });
//     return response;
//   } catch (error) {
//     console.error(`Error making API request: ${error}`);
//     throw error; // Optionally, you can rethrow the error for handling in the caller
//   }
// };
