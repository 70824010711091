import React, { useContext } from "react";
import Layout from "../components/layout/Layout";
import AlbumDetail from "../components/albumDetail/AlbumDetail";
// import { AudioProvider } from "../components/AudioContext/AudioContext";
import { useAudio } from "../components/AudioContext/AudioContext";

const AlbumDetails = () => {
  const {
    handlePlay,
    handleStop,
    setAudioDetails,
    albumDetails,
    setAlbumDetails,
    authorDetails,
    setAuthorDetails,
    favouriteTrackIds,
    setFavouriteTrackIds,
    user_id,
    setHandleStopButton,
    setCurrentTime,
    pauseAudio,
    AudioPchangeAudioSource
  } = useAudio();
  return (
    <div>
      <Layout>
        <AlbumDetail
          onPlay={handlePlay}
          onStop={handleStop}
          setAudioDetails={setAudioDetails}
          albumDetails={albumDetails}
          setAlbumDetails={setAlbumDetails}
          authorDetails={authorDetails}
          setAuthorDetails={setAuthorDetails}
          favouriteTrackIds={favouriteTrackIds}
          setFavouriteTrackIds={setFavouriteTrackIds}
          user_id={user_id}
          setHandleStopButton={setHandleStopButton}
          setCurrentTime={setCurrentTime}
          pauseAudio={pauseAudio}
          AudioPchangeAudioSource={AudioPchangeAudioSource}
        />
      </Layout>
    </div>
  );
};

export default AlbumDetails;
