import React, { useEffect, useState } from "react";
import "../style/pagelist.css";
import CardSlider from "../common/CardSlider";

const PageListing = ({ title, data, featured, featuredTracks, favourites }) => {
  const [slidesMainPerShow, setSlidesMainPerShow] = useState(4);
  const [slidesPerShow, setSlidesPerShow] = useState(6);

  useEffect(() => {
    const updateSlidesPerShow = () => {
      // console.log(window.innerWidth);
      if (window.innerWidth < 565) {
        setSlidesMainPerShow(1.5);
        setSlidesPerShow(2.5);
      } else if (window.innerWidth < 767) {
        setSlidesMainPerShow(3);
        setSlidesPerShow(2.5);
      } else {
        setSlidesMainPerShow(4);
        setSlidesPerShow(6);
      }
    };

    updateSlidesPerShow();

    window.addEventListener("resize", updateSlidesPerShow);

    return () => {
      window.removeEventListener("resize", updateSlidesPerShow);
    };
  }, []);

  return (
    <>
      <section className="calm_main_page">
        <div className="calm_main_page_wrapperr">
          {featured?.map((data, index) => {
            if (index == 0) {
              return (
                <>
                  <CardSlider
                    data={featured}
                    title={"Featured"}
                    slidesPerShow={
                      index === 0 ? slidesMainPerShow : slidesPerShow
                    }
                    // cardHeight={index === 0 ? "cardHeight" : ""}
                    cardSpacing={data?.todayDailie && data?.todayDailie}
                    index={index}
                    cardHeight="cardHeight"
                    tracks={featuredTracks}
                  />
                </>
              );
            }
          })}
          {favourites?.map((data, index) => {
            if (index == 0) {
              return (
                <>
                  <CardSlider
                    data={favourites}
                    title={"Favourites"}
                    slidesPerShow={
                      index === 0 ? slidesMainPerShow : slidesPerShow
                    }
                    // cardHeight={index === 0 ? "cardHeight" : ""}
                    cardSpacing={data?.todayDailie && data?.todayDailie}
                    index={index}
                    cardHeight="cardHeight"
                  />
                </>
              );
            }
          })}
          {data?.map((data, index) => {
            // console.log("today::", data);
            return (
              <>
                <CardSlider
                  parentTitle={title}
                  data={data?.albums}
                  title={data?.name}
                  category_id={data?.id}
                  slidesPerShow={slidesPerShow}
                  // cardHeight="230px"
                  cardSpacing={data?.todayDailie && data?.todayDailie}
                  index={index}
                  tracks={data?.tracks}
                />
              </>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default PageListing;
