import React, { useEffect, useState } from "react";
import "../style/subscription.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import { CancelSubscriptionModal } from "../common/CancelSubscriptionModal";

const Subscription = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState();
  const token = localStorage.getItem("token");
  // useEffect(() => {
  //   if (token) {
  //     const tokenData = jwtDecode(token);
  //     setSubscriptionStatus(tokenData?.user);
  //     console.log("timestamp::::   ", tokenData?.user?.cancel_date)
  //   }
  // }, []);
  // import jwtDecode from "jwt-decode";

useEffect(() => {
  if (token) {
    const tokenData = jwtDecode(token);
    setSubscriptionStatus(tokenData?.user);
    
    const cancelTimestamp = tokenData?.user?.cancel_date;
    if (cancelTimestamp) {
      const cancelDate = new Date(cancelTimestamp * 1000);
      setDate(cancelDate.toLocaleString());
    }
  }
}, [token]);


  const handleModal = () => {
    setModalOpen(!modalOpen);
    if (modalOpen) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };
  // console.log({ subscriptionStatus });
  return (
    <>
      <section className="manage-subscription">
        {subscriptionStatus?.subscription_status == "active" ? (
          <div className="manage-subscription-wrapper">
            <div className="manage-subscription-title">
              <h2>{t("profile.Subscribed.title")}</h2>
            </div>
            <div className="manage-subscription-details">
              {subscriptionStatus?.cancel_reason && (
                <p>{t("profile.Canceled.description", {date})}</p>
              )}
              {!subscriptionStatus?.cancel_reason && (
                <>
                  <p>{t("profile.Subscribed.description")}</p>
                  <button
                    className="btn-primary"
                    onClick={() => setModalOpen(true)}
                  >
                    {t("profile.Subscribed.CancelSubscription")}
                  </button>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="manage-subscription-wrapper">
            <div className="manage-subscription-title">
              <h2>{t("profile.Manage Subscription.title")}</h2>
            </div>
            <div className="manage-subscription-details">
              <p>{t("profile.Manage Subscription.description")}</p>
              <button
                className="btn-primary"
                onClick={() => navigate("/new-member-offer")}
              >
                {t("profile.Manage Subscription.Plenum Premium")}
              </button>
            </div>
          </div>
        )}
      </section>
      {modalOpen && (
        <div
          className={`auth-modal-overlay ${
            modalOpen ? "user-modal-overlay" : ""
          }`}
        >
          <CancelSubscriptionModal showModal={handleModal} />
        </div>
      )}
    </>
  );
};

export default Subscription;
