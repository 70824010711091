import React, { useState } from "react";
import Layout from "../components/layout/Layout.jsx";
import UserProfile from "../components/profile/UserProfile.jsx";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  const [showProfile, setShowProfile] = useState(true);
  const [showTabs, setShowTabs] = useState(false);
  const [profileTitle , setProfileTitle] = useState(t("sidebar.profile"))
  const [showLogout, setShowLogout] = useState(false)
  // console.log({ showLogout });

  return (
    <Layout title={profileTitle} setShowProfile={setShowProfile} setShowTabs={setShowTabs} showLogout={showLogout}>
      <UserProfile showProfile={showProfile} setShowProfile={setShowProfile} showTabs={showTabs} setShowTabs={setShowTabs} handleProfileTitle={setProfileTitle} setShowLogout={setShowLogout}/>
    </Layout>
  );
};

export default Profile;
