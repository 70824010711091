// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calm_main_page_wrapper{
    display: flex;
    gap:1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/style/pagelist.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;AACd","sourcesContent":[".calm_main_page_wrapper{\n    display: flex;\n    gap:1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
