import React, { useEffect, useState } from "react";
import "../style/albumDetail.css";
import DailyCalm from "../../assets/daily_calm.jpeg";
import { CiLock } from "react-icons/ci";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { CiHeart } from "react-icons/ci";
import { FaHeart } from "react-icons/fa";
import MusicPlayer from "../common/MusicPlayer";
import LockModal from "../common/authModal/LockModal";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { globalApiCalling } from "../../global";
import { useNavigate } from "react-router-dom";
import { useAudio } from "../AudioContext/AudioContext";
import { jwtDecode } from "jwt-decode";
import { getFavouritesById, toggleFavouriteTrack } from "../../Api/helper";

const AlbumDetail = ({
  onPlay,
  setAudioDetails,
  albumDetails,
  setAlbumDetails,
  authorDetails,
  setAuthorDetails,
  favouriteTrackIds,
  setFavouriteTrackIds,
  user_id,
  setHandleStopButton,
  setCurrentTime,
  pauseAudio,
  AudioPchangeAudioSource
}) => {
  const [clickedHearts, setClickedHearts] = useState([]);
  const [showLockModal, setLockShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const handleLockModal = () => {
    setLockShowModal(!showLockModal);
    if (showLockModal) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };
  // const getToken = localStorage.getItem("token");
  // const decoded = jwtDecode(getToken);
  // const user_id = decoded.user.id;

  const navigate = useNavigate();
  const { album_id } = useParams();
  // console.log({ album_id });
  const handleHeartClick = async (index, track_id) => {
    setClickedHearts((prevClickedHearts) =>
      prevClickedHearts.includes(index)
        ? prevClickedHearts.filter((i) => i !== index)
        : [...prevClickedHearts, index]
    );

    const body = { user_id, track_id };
    const result = await toggleFavouriteTrack(body);
    if (!result?.data?.isSuccess) {
      // console.log("error is as:  ", result?.data?.message);
    } else {
      if (favouriteTrackIds.includes(track_id)) {
        setFavouriteTrackIds(
          favouriteTrackIds.filter((item) => item !== track_id)
        );
      } else {
        setFavouriteTrackIds([...favouriteTrackIds, track_id]);
      }
    }
  };

  const getAlbumsTracks = async () => {
    try {
      const response = await globalApiCalling(
        setLoading,
        "GET",
        `${process.env.REACT_APP_BASE_URL}track/album/${album_id}?limit=10000`,
        null
      );
      if (response?.response?.data?.isSuccess === false) {
        // console.log(response?.response?.data?.message);
        toast.error(response?.response?.data?.message);
        return;
      } else {
        if (response?.data?.data) {
          setItem(response?.data?.data);
          setAlbumDetails({
            albumDescription: response?.data?.data[0]?.album?.long_description,
            albumTitle: response?.data?.data[0]?.album?.title,
          });
          // setAuthorDetails({
          //   name: response?.data?.data[0]?.author?.name,
          //   image: response?.data?.data[0]?.author?.image,
          //   bio: response?.data?.data[0]?.author?.bio,
          // });
          setAuthorDetails({
            name: response?.data?.data[0]?.album?.author?.name,
            image: response?.data?.data[0]?.album?.author?.image,
            bio: response?.data?.data[0]?.album?.author?.bio,
          });
          setIsLoading(false);
          // toast.success(response?.data?.message);
        }
        return;
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getAlbumsTracks();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    let tokenData;
    if (token) {
      tokenData = jwtDecode(token);
      setSubscriptionStatus(tokenData?.user?.subscription_status);
    }
  }, []);
  const formatDuration = (duration) => {
    if (duration < 60) {
      return `${duration} s`;
    } else if (duration < 3600) {
      // less than 1 hour
      const minutes = Math.floor(duration / 60);
      const seconds = duration % 60;
      return seconds > 0 ? `${minutes} m ${seconds} s` : `${minutes} m`;
    } else {
      // 1 hour or more
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = duration % 60;
      let result = `${hours} h`;
      if (minutes > 0) {
        result += ` ${minutes} m`;
      }
      if (seconds > 0) {
        result += ` ${seconds} s`;
      }
      return result;
    }
  };
  // const formatDuration = (duration) => {
  //   if (duration < 60) {
  //     return `${duration}`;
  //   } else {
  //     const hours = Math.floor(duration / 60);
  //     const minutes = duration % 60;
  //     return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
  //   }
  // };
  const items = [
    {
      title: "Practice Gratitude",
      duration: "12m",
      isPaid: false,
    },
    {
      title: "Love and Accept Yourself",
      duration: "13m",
      isPaid: true,
    },
    { title: "Learn to Let Go", duration: "13m", isPaid: true },
  ];
  const token = localStorage.getItem("token") || null;

  return (
    <section className="album-deatils-main-container">
      {isLoading ? (
        <div style={{ margin: "auto" }} class="loader"></div>
      ) : (
        <div className="details-container section-spacing">
          <div className="details-header">
            <h1>{albumDetails?.albumTitle}</h1>
            <p>{albumDetails?.albumDescription}</p>
          </div>
          <div className="details-author">
            {/* <img
              className="author-avatar"
              src={authorDetails?.image}
              alt="image"
            /> */}
            {authorDetails?.image && (
              <img
                className="author-avatar"
                src={authorDetails?.image}
                alt="no author"
              />
            )}
            <div className="author-info">
              <p className="author-name">{authorDetails?.name}</p>
              <p className="author-title">{authorDetails?.bio}</p>
            </div>
          </div>
          <ul className="details-list section-spacing">
            {item?.map((item, index) => (
              <li
                key={index}
                className="details-item cursor-pointer"
                onClick={() => {
                  if (subscriptionStatus == "active") {
                    if (item?.file_path) {
                      pauseAudio();
                      setCurrentTime(0);
                      setAudioDetails({
                        id: item?.id,
                        source: item?.file_path,
                        name: item?.title,
                        // album: albumDetails?.albumTitle,
                        album: item?.album?.title,
                        image: item?.image,
                        duration: item?.duration,
                      });
                      AudioPchangeAudioSource(item?.file_path);
                      setHandleStopButton(false);
                      onPlay();
                      // setAuthorDetails({
                      //   name: item?.author?.name,
                      // });
                    } else {
                      navigate(`/details/${item.id}`);
                    }
                  } else if (item?.is_paid && token) {
                    navigate("/new-member-offer");
                  } else if (item?.is_paid && !token) {
                    handleLockModal();
                  } else if (item?.file_path) {
                    pauseAudio();
                    onPlay();
                    setAudioDetails({
                      id: item?.id,
                      source: item?.file_path,
                      name: item?.title,
                      album: albumDetails?.albumTitle,
                      image: item?.image,
                      duration: item?.duration,
                    });
                    AudioPchangeAudioSource(item?.file_path);
                    setHandleStopButton(false);
                    setCurrentTime(0);
                  }
                }}
              >
                <div className="details-item-left">
                  <div className="details-left-content">
                    <div className="details-inner-left-content">
                      <h4 className="details-title">{item?.title}</h4>
                    </div>
                    <p className="details-duration">
                      {formatDuration(item?.duration)}
                    </p>
                  </div>
                </div>
                <div className="details-item-right">
                  
                {subscriptionStatus == "active" ? (favouriteTrackIds.includes(item.id) ? (
                    <FaHeart
                      onClick={(e) => {
                        e.stopPropagation();
                        handleHeartClick(index, item.id);
                      }}
                    />
                  ) : (
                    <CiHeart
                      onClick={(e) => {
                        e.stopPropagation();
                        handleHeartClick(index, item.id);
                      }}
                    />
                  )) : (item?.is_paid ? (
                    <CiLock
                    // onClick={(e) => {
                    //   e.stopPropagation();
                    //   handleLockModal();
                    // }}
                    />
                  ) : (
                    favouriteTrackIds.includes(item.id) ? (
                      <FaHeart
                        onClick={(e) => {
                          e.stopPropagation();
                          handleHeartClick(index, item.id);
                        }}
                      />
                    ) : (
                      <CiHeart
                        onClick={(e) => {
                          e.stopPropagation();
                          handleHeartClick(index, item.id);
                        }}
                      />
                    )
                  ))}
                </div>
              </li>
            ))}
          </ul>
          {/* <MusicPlayer /> */}
          {showLockModal && subscriptionStatus != "active" && (
            <div
              className={`auth-modal-overlay ${
                showLockModal ? "user-modal-overlay" : ""
              }`}
            >
              <LockModal showLockModal={handleLockModal} />
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default AlbumDetail;
