"use client";

import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  ExpressCheckoutElement,
  CardElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "../../components/style/payment.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { GetEncryptedSingleUser, UpdateUser } from "../../Api/helper";
import { CiLock } from "react-icons/ci";

const Checkout = ({ amount, stripePromise, selectedPackage }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState();
  const [clientSecret, setClientSecret] = useState("");
  // const [user_id, setUser_id] = useState();
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const decoded = jwtDecode(token);
  const user_id = decoded?.user?.id;
  const is_trial_taken = decoded?.user?.is_trial_taken;
  // console.log("isTrialTaken:::    ", is_trial_taken);
  // if (token) {
  // const decoded = jwtDecode(token);
  // const user_id = decoded?.user?.id;
  // setUser_id(decoded?.user?.id);
  // }
  const navigate = useNavigate();
  function convertToSubcurrency(amount, factor = 100) {
    return Math.round(amount * factor);
  }
  const [isPaymentElementComplete, setIsPaymentElementComplete] =
    useState(false);
  const [isGooglePayAvailable, setIsGooglePayAvailable] = useState(false);
  useEffect(() => {
    const checkGooglePay = async () => {
      // console.log("window.PaymentRequest", window.PaymentRequest);
      if (window.PaymentRequest) {
        const stripe = await stripePromise;

        const paymentRequest = stripe.paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: "Demo total",
            amount: convertToSubcurrency(amount),
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        paymentRequest.canMakePayment().then((result) => {
          // console.log(
          //   "result && result.applePay || result.googlePay",
          //   result,
          //   result?.applePay,
          //   result?.googlePay
          // );
          if (result && (result?.applePay || result?.googlePay)) {
            setIsGooglePayAvailable(true);
            setTimeout(() => {
              const element1 = document.querySelector(
                ".google-pay-stripe-element"
              );
              // console.log("google element1", element1);
              const element = document.querySelector(
                ".google-pay-stripe-element .__PrivateStripeElement"
              );
              // console.log("google element", element);
              if (element) {
                element.style.borderRadius = "100px";
                element.style.padding = "0.4rem 0.875rem";
                element.style.background = "black";
              }
            }, 3000);
            // toast.success("PaymentRequest API success")
          } else {
            console.error("PaymentRequest API not supported");
          }
        });
      } else {
        console.error("PaymentRequest API not supported");
      }
    };

    checkGooglePay();
  }, []);
  useEffect(() => {
    if (!elements) {
      return;
    }
    const paymentElement = elements.getElement(PaymentElement);

    if (paymentElement) {
      const handleChange = (event) => {
        setIsPaymentElementComplete(event.complete);
      };

      paymentElement?.on("change", handleChange);

      return () => {
        paymentElement?.off("change", handleChange);
      };
    }
  }, [elements]);
  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      setLoading(true);

      if (!stripe || !elements) {
        return;
      }
      const { error: submitError } = await elements.submit();

      if (submitError) {
        setErrorMessage(submitError.message);
        setLoading(false);
        return;
      }

      const result = await stripe.createPaymentMethod({
        elements,
      });

      // console.log("result", result);
      // console.log("result?.paymentMethod?.id", result?.paymentMethod?.id);

      // console.log("paymentMethod", paymentMethodId);
      let data;
      if (is_trial_taken) {
        const APIresult = await axios.post(
          `${process.env.REACT_APP_BASE_URL}subscription/create-without-trial`,
          {
            amount: convertToSubcurrency(amount),
            paymentMethodId: result?.paymentMethod?.id,
            userId: user_id,
            selectedPackage: selectedPackage,
          }
        );
        data = APIresult?.data;
      } else {
        const APIresult = await axios.post(
          `${process.env.REACT_APP_BASE_URL}subscription/create`,
          {
            amount: convertToSubcurrency(amount),
            paymentMethodId: result?.paymentMethod?.id,
            userId: user_id,
            selectedPackage: selectedPackage,
          }
        );
        
        data = APIresult?.data;
      }
      // console.log("response", data, data?.clientSecret);
      if (data?.isSuccess) {
        setClientSecret(data?.clientSecret);
        toast.success(data?.message);
        const body = new FormData();
        body.append("is_trial_taken", true);
        const updateUser = await UpdateUser(body, user_id);
        const result = await GetEncryptedSingleUser(user_id);
        if (result) {
          localStorage.setItem("token", result?.data?.data);
        }

        navigate("/");
      } else {
        toast.error(data?.message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    const handleConfirm = async (event) => {
      try {
        // event.preventDefault();
        // if (event) {
        //   event.preventDefault();
        // }
        await handleSubmit();
      } catch (error) {
        setErrorMessage(error.message);
        setLoading(false);
      }
    };

    if (elements) {
      const expressCheckoutElement = elements.getElement(
        ExpressCheckoutElement
      );
      if (expressCheckoutElement) {
        expressCheckoutElement.on("confirm", handleConfirm);
      }
    }

    return () => {
      if (elements) {
        const expressCheckoutElement = elements.getElement(
          ExpressCheckoutElement
        );
        if (expressCheckoutElement) {
          expressCheckoutElement.off("confirm", handleConfirm);
        }
      }
    };
  }, [elements]);

  return (
    <form onSubmit={handleSubmit} className="p-2 rounded-md">
      <div className="bg-white">
        <ExpressCheckoutElement
          className="google-pay-stripe-element"
          style={{ display: isGooglePayAvailable ? "block" : "none" }}
        />
        {isGooglePayAvailable && (
          // <>
          <p className="payment-option">Or</p>
          // </>
        )}
        <PaymentElement />
        {/* <CardElement/> */}

        {errorMessage && <div>{errorMessage}</div>}
      </div>
      {!is_trial_taken && (
        <p style={{ margin: "40px 0 10px 0", color: "#bababa" }}>
          By clicking "Start Free Trial", you agree to be automatically billed
          for Plenum Premium at US$39.99 after your trial ends every year until
          cancelled. <span style={{textDecoration: "underline"}}>Cancel anytime.</span>
        </p>
      )}
      <button
        disabled={!stripe || loading || !isPaymentElementComplete}
        className="payment-submit-btn text-white w-full p-5 bg-black mt-2 rounded-md font-bold disabled:opacity-50 disabled:animate-pulse"
        style={{ cursor: "pointer", marginTop: is_trial_taken ? "50px" : "20px", }}
      >
        {/* {!loading ? `Get Plenum Premium` : "Processing..."} */}
        {!loading ? (is_trial_taken ? `Pay Now` : `Start Free Trial`) : "Processing..."}
      </button>
      <p style={{ color: "#bababa" }}>
        <CiLock style={{ position: "relative", top: 5, left: -5 }} /> All
        Transactions are secure and encrypted.
      </p>
    </form>
  );
};

export default Checkout;
