import React from "react";
import Layout from "../components/layout/Layout";
import ScenesList from "../components/scenes/Scenes";
import { useTranslation } from "react-i18next";

const Scenes = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Layout title={t("scenes.title")}>
        <ScenesList />
      </Layout>
    </div>
  );
};

export default Scenes;
