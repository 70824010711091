import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { cancelSubscription, GetEncryptedSingleUser, GetSingleUser } from "../../Api/helper";
import {jwtDecode} from "jwt-decode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const CancelSubscriptionModal = ({ showModal }) => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState();

    useEffect(() => {
        const token = localStorage.getItem("token");
        const data = jwtDecode(token);
        setUserId(data?.user?.id);
    }, []);

    const handleCancelSubscription = async () => {
        const result = await GetSingleUser(userId);
        const cancelSubscriptionId = result?.data?.data?.stripeUser?.stripe_subscription_id;
        const body = { subscription_id: cancelSubscriptionId };
        const cancelResult = await cancelSubscription(body);
        if (cancelResult?.data?.isSuccess) {
            toast.success(cancelResult?.data?.message);
            const result = await GetEncryptedSingleUser(userId);
            if (result) {
                localStorage.setItem("token", result?.data?.data);
            }
            navigate("/");
            showModal();
        }
    };

    return (
        <div className="modal-overlay cancel-sub-modal">
            <div className="modal-content">
                <RxCross2 className="close-icon" onClick={showModal} />
                <h2 style={{color: "black", textAlign: "left"}}>Confirm Cancel Subscription?</h2>
                <p style={{color: "black", textAlign: "left"}}>You will permanently cancel the Subscription.</p>
                <div className="cancel-modal-button-group">
                    <button className="btn-cancel" onClick={showModal}>Not now</button>
                    <button className="btn-confirm" onClick={handleCancelSubscription}>Cancel Subscription</button>
                </div>
            </div>
        </div>
    );
};
