import React, { useEffect, useState } from "react";
import "../style/privacyPolicy.css";
import { getTermAndConditions } from "../../Api/helper";
import { useTranslation } from "react-i18next";

const TermsConditionList = () => {
  const { t } = useTranslation();
  const [updated, setUpdated] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    const getData = async () => {
      const response = await getTermAndConditions();
      // console.log(
      //   "terms and conditions response: ",
      //   response.data.data.content
      // );
      setContent(response?.data?.data?.content);
      const updatedDate = new Date(response?.data?.data?.updated_at);
      const formattedDate = updatedDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      setUpdated(formattedDate);
    };
    getData();
  }, []);

  return (
    <>
      <section className="terms-condition-wrapper section-spacing" style={{borderRadius: "20px"}}>
        <div style={{ margin: "20px 60px" }}>
          {/* <p>
            {t("termsCondition.text1")}
            <br />
            <br />
            {t("termsCondition.text2")}
            <strong>{t("termsCondition.text3")}</strong>{" "}
            {t("termsCondition.text4")}
            <br />
            <br />
            {t("termsCondition.text5")}
            <strong>{t("termsCondition.text6")}</strong>{" "}
            {t("termsCondition.text7")}
            <br />
            <br />
          </p> */}
          <h1>{t("termsCondition.header")}</h1>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            {t("termsCondition.lastUpdated")}
            {updated}
          </p>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </section>
    </>
  );
};

export default TermsConditionList;
