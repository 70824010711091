import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../common/Card";
import "../style/detail.css";
import LockModal from "../common/authModal/LockModal";

const Detail = ({ title, data }) => {

  const [showLockModal, setLockShowModal] = useState(false);
  const handleLockModal = () => {
    setLockShowModal(!showLockModal);
    if (showLockModal) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <>
      {/* <h2>{title?.toUpperCase()}</h2> */}
      <div className="detail_container">
        {data?.map((item, index) => (
          <div key={index} style={{ minWidth: `calc(100% / ${4})` }}>
            <Card data={item} category={item?.category?.name} handleLockModal={handleLockModal} cardHeight="190px" />
          </div>
        ))}
      </div>
      {showLockModal && (
                <div
                  className={`auth-modal-overlay ${
                    showLockModal ? "user-modal-overlay" : ""
                  }`}
                >
                  <LockModal showLockModal={handleLockModal} />
                </div>
              )}
    </>
  );
};

export default Detail;
