import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout/Layout";
import { IoVolumeHighOutline, IoVolumeMuteOutline } from "react-icons/io5";
import axios from "axios";
import { useAudio } from "../AudioContext/AudioContext";
import "../style/scenes.css";
import { GetAllScenes } from "../../Api/helper";
import AudioPlayer from "react-h5-audio-player";
// import { IoVolumeHighOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const Scenes = () => {
  const { t, i18n } = useTranslation();
  const [scenesData, setScenesData] = useState([]);
  const [selectedScene, setSelectedScene] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const {
    playAudio,
    currentTrack,
    isMuted,
    muteAudio,
    unmuteAudio,
    setFullVolume,
    volume,
    changeVolume,
    isChecked,
    backgroundImage,
    setBackgroundImage,
    isPlaying,
    pauseAudio,
    AudioPstop,
    AudioPisPlaying,
    setAudioPisPlaying,
    setHandleStopButton,
    handleStop,
    stopAudio
  } = useAudio();

  // Update local storage whenever the checkbox status changes
  const handleCheckboxChange = (event) => {
    isChecked.current = event.target.checked;
    // console.log(event.target.checked);
  };

  useEffect(() => {
    const getAllScenes = async () => {
      try {
        const response = await GetAllScenes();
        if (response?.data?.isSuccess) {
          setScenesData(response?.data?.data?.rows);
          if (scenesData) {
            setIsLoading(false);
          }
        } else {
          console.error("Failed to fetch scenes:", response?.data?.message);
        }
      } catch (error) {
        console.error("Error fetching scenes data:", error);
      }
    };
    getAllScenes();
  }, []);

  useEffect(() => {
    if (selectedScene) {
      if (AudioPisPlaying) {
        setHandleStopButton(true);
        setAudioPisPlaying(false);
        AudioPstop();
        handleStop();
        stopAudio();
      }
      if (isPlaying) {
        pauseAudio();
      } else {
        AudioPstop();
        playAudio(selectedScene);
      }
    }
  }, [selectedScene, playAudio]);

  const handleVolumeMax = () => {
    if (isMuted) {
      unmuteAudio();
    }
    setFullVolume();
  };

  const handleVolumeChange = (e) => {
    if (e.target.value > 0 && isMuted) {
      unmuteAudio();
    }
    if ((e.target.value == 0) & !isMuted) {
      muteAudio();
    }
    changeVolume(parseFloat(e.target.value));
  };

  return (
    <>
      {isLoading ? (
        <div style={{ margin: "auto" }} className="loader"></div>
      ) : (
        <section className="scenes-container">
          <div className="scenes_wrapper">
            <div className="scenes-audio">
              <p style={{ fontSize: "16px" }}>{t("scenes.volume")}</p>
              <div className="audio-controls-scenes">
                {isMuted ? (
                  <IoVolumeMuteOutline
                    className="muteVol"
                    onClick={unmuteAudio}
                  />
                ) : (
                  <IoVolumeHighOutline
                    className="muteVol"
                    onClick={muteAudio}
                  />
                )}
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={handleVolumeChange}
                  className="volume-slider"
                  style={{
                    // marginLeft: "20px",
                    background: `linear-gradient(to right, #FFEB3B ${
                      volume * 100
                    }%, #ccc ${volume * 100}%)`,
                  }}
                />
                <IoVolumeHighOutline
                  className="fullVol"
                  onClick={handleVolumeMax}
                />
              </div>
            </div>
            <div className="scense-checkbox" style={{ marginTop: "10px" }}>
              <input
                type="checkbox"
                checked={isChecked.current.current}
                onChange={handleCheckboxChange}
                style={{ width: "15px", height: "15px" }}
              />
              <span style={{ fontSize: "18px", fontWeight: 400 }}>
                {t("scenes.idle")}
              </span>
            </div>
          </div>

          <section className="scenes-card">
            {scenesData?.map((data) => (
              <button
                style={{
                  background: "none",
                  border:
                    currentTrack?.name === data.name
                      ? "4px solid white"
                      : "none",
                  borderRadius: "20px",
                }}
                onClick={() => {
                  setSelectedScene(data);
                  setBackgroundImage(data?.image);
                  localStorage.setItem("bgImage", data?.image);
                }}
                key={data.id}
                className="scense-card-wrapper"
              >
                <div className="scense-card-image">
                  <img src={data?.image} alt={data?.title} />
                </div>
                <div className="scense-card-content">
                  <h5>{data?.name}</h5>
                </div>
              </button>
            ))}
          </section>
        </section>
      )}
    </>
  );
};

export default Scenes;
