import React, { useEffect, useState } from "react";
import "../style/shareModal.css";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const ShareModal = ({ shareModal }) => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const link = "https://plenumapp.com" + location.pathname;

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard!");
    shareModal()
  };

  useEffect(() => {
    const sidebar = document.querySelector(".calm_sidebar");
    if (showModal) {
      sidebar.classList.add("blur-background");
    } else {
      sidebar.classList.remove("blur-background");
    }
  }, [showModal]);

  return (
    <>
      <div className="share-modal-container">
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-modal-button" onClick={shareModal}>
              ×
            </button>
            <h2
              style={{
                color: "#898989",
                marginTop: "10px",
                fontSize: "23px",
                fontWeight: 500,
              }}
            >
              Share
            </h2>
            <p
              style={{
                color: "#898989",
                fontSize: "18px",
                fontWeight: 400,
                margin: "20px 0px",
                lineHeight: "normal",
              }}
            >
              Share free access to this content by copying the link below!
            </p>
            <p
              style={{ color: "#898989", fontWeight: 700 }}
              className="click-to-copy"
              onClick={handleCopy}
            >
              Click to copy
            </p>
            <div
              className="link-container"
              style={{
                backgroundColor: "none",
                border: "1px solid #d4d4d4",
                height: "50px",
                marginBottom: "20px",
              }}
            >
              <span className="link-icon">🔗</span>
              <input type="text" value={link} readOnly className="link-input" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareModal;
