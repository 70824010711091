import React, { useRef, useState,useEffect } from "react";
import { FaRegCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "../style/sidebar.css";
import { GoHome } from "react-icons/go";
import { GoMoon } from "react-icons/go";
import { IoMusicalNotesOutline } from "react-icons/io5";
import { BsCupHot } from "react-icons/bs";
import { HiOutlineLightBulb } from "react-icons/hi2";
import { SlFlag } from "react-icons/sl";
import { FaRegUserCircle } from "react-icons/fa";
import { IoManOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdClose } from "react-icons/md";
import logo from "../../assets/plenum-logo-1.png";
import { useTranslation } from "react-i18next";
import { useAudio } from "../AudioContext/AudioContext";

const Sidebar = ({ leftNavData, isModalOpen, setisModalOpen, setIsLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [navActive, setNavActive] = useState("Home");
  const sidebarRef = useRef();
  const { navActive, setNavActive } = useAudio();
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  useEffect(() => {
    if (sidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [sidebarOpen]);
  const iconMapping = {
    Home: <GoHome />,
    Meditate: <FaRegCircle />,
    Sleep: <GoMoon />,
    Music: <IoMusicalNotesOutline />,
    "For Work": <BsCupHot />,
    Wisdom: <HiOutlineLightBulb />,
    "Calm Kids": <SlFlag />,
    Movement: <IoManOutline />,
  };

  // Function to get the icon based on item.name
  const getIcon = (name) => {
    return iconMapping[name] || <FaRegCircle />;
  };

  const handleNavClick = (name) => {
    setNavActive(name);
    if (sidebarOpen) toggleSidebar();
  };

  return (
    <>
      <section
        className={`calm_sidebar ${
          sidebarOpen
            ? "active calm_sidebar_mobile_v"
            : "calm_sidebar_mobile_close"
        } side-bar-hide`}
        ref={sidebarRef}
      >
        <div
          className={`calm_sidebar_wrapper ${
            isModalOpen ? "sidebar_background_blur" : "sidebar_background"
          }`}
          onMouseOver={(e) => (e.currentTarget.style.overflowY = "auto")}
          onMouseOut={(e) => (e.currentTarget.style.overflowY = "hidden")}
        >
          <div className="sc-11570cec-3 gjInXl mbl-nav-icon">
            <button
              className="sc-e5926425-0 UzAjP sc-68d19ce1-1 fQwLX"
              aria-label="Open Mobile menu"
              aria-expanded="false"
              aria-controls="mobile-menu"
              type="button"
              tabIndex={0}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                border: "none",
              }}
              onClick={toggleSidebar}
            >
              {!sidebarOpen ? <MdClose /> : <MdClose />}
            </button>
          </div>
          <div className="logo">
            <Link to="/Home/1">
              {/* <p>Plenum</p> */}
              <img src={logo} alt="logo" className="logo-img" />
            </Link>
          </div>
          <div className="calm_nav_menu">
            {/* <Link to="/">
              <div className="nav_menu_content">
                <div className="nav_icon">
                  <GoHome />
                </div>
                <div className="nav_text">
                  <p>{t("sidebar.home")}</p>
                </div>
              </div>
            </Link> */}
            {leftNavData ? (
              <>
                {leftNavData?.map((item) => {
                  return (
                    <>
                      {!item?.parent_category_id ? (
                        <Link
                          to={`/${item?.name}/${item.id}`}
                          onClick={() => {
                            // setIsLoading(true);
                            if (setIsLoading) setIsLoading(true);
                            setisModalOpen(false);
                            sidebarOpen && toggleSidebar();
                            handleNavClick(item?.name);
                          }}
                        >
                          <div className="nav_menu_content">
                            {/* <div className="nav_icon">
                              <FaRegCircle />
                            </div> */}
                            <div
                              className={
                                navActive === item?.name
                                  ? "active nav_icon"
                                  : "nav_icon"
                              }
                            >
                              {getIcon(item?.name)}
                            </div>
                            <div className="nav_text">
                              <p>{item?.name}</p>
                            </div>
                          </div>
                        </Link>
                      ) : null}
                    </>
                  );
                })}
              </>
            ) : null}
            <Link
              to="/profile"
              onClick={() => {
                sidebarOpen && toggleSidebar();
                handleNavClick("profile");
              }}
            >
              <div className="nav_menu_content">
                <div
                  className={
                    navActive === "profile" ? "active nav_icon" : "nav_icon"
                  }
                >
                  <FaRegUserCircle />
                </div>
                <div className="nav_text">
                  <p>{t("sidebar.profile")}</p>
                </div>
              </div>
            </Link>
            <div className="nav_menu_content" style={{ marginTop: "40px" }}>
              <div className="nav_text">
                <Link to="/privacy-policy">{t("sidebar.privacy policy")}</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="claim_sidebar_mobile">
        <div className="sc-11570cec-0 gStUjJ">
          <Link to="/">
            {/* <p>Plenum</p> */}
            <img src={logo} alt="logo" className="logo-img" />
          </Link>
          <div className="sc-11570cec-3 gjInXl">
            <button
              className="sc-e5926425-0 UzAjP sc-68d19ce1-1 fQwLX"
              aria-label="Open Mobile menu"
              aria-expanded="false"
              aria-controls="mobile-menu"
              type="button"
              tabIndex={0}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                border: "none",
              }}
              onClick={toggleSidebar}
            >
              {!sidebarOpen ? <RxHamburgerMenu /> : <MdClose />}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
