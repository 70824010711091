// import { useEffect } from 'react'; // Adjust import to use 'react' module
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "./Checkout";
import React from "react";
import SvgComponent from "../common/SvgComponent";
import { Link } from "react-router-dom";

if (process.env.REACT_APP_STRIPE_PUBLIC_KEY === undefined) {
  throw new Error("REACT_APP_STRIPE_PUBLIC_KEY is not defined");
}
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CreatePayment = ({amount, selectedPackage}) => {
  // const amount = 49.99;
  function convertToSubcurrency(amount, factor = 100) {
    return Math.round(amount * factor);
  }
  return (
    <main className="max-w-6xl mx-auto p-10 text-white text-center border m-10 rounded-md bg-gradient-to-tr from-blue-500 to-purple-500">
      {/* <Link to="#">
        <div className="payment-btn">
          <SvgComponent name="PayPal" />
        </div>
      </Link> */}
      <Elements
        stripe={stripePromise}
        options={{
          mode: "payment",
          amount: convertToSubcurrency(amount),
          currency: "usd",
          paymentMethodCreation: "manual",
        }}
      >
        <Checkout amount={amount} stripePromise={stripePromise} selectedPackage={selectedPackage}/>
      </Elements>
    </main>
  );
};

export default CreatePayment;
