import React, { useEffect, useState } from "react";
import "../style/subscription.css";
import PasswordUpdate from "../profile/PasswordUpdate";
import AccountDetails from "../profile/AccountDetails";
import Subscription from "./Subscription";
import CategoriesHeader from "../common/CategoriesHeader";
import { useTranslation } from "react-i18next";

const ProfileTabs = ({ activeTabPassed, handleProfileTitle, profileTitle, setShowLogout }) => {
  const [activeTab, setActiveTab] = useState(activeTabPassed);
  const { t, i18n } = useTranslation();
  const categoriesData = [
    {
      id: 1,
      title: t("profile.profileList.0"),
    },
    {
      id: 2,
      title: t("profile.profileList.1"),
    },
    {
      id: 3,
      title: t("profile.profileList.2"),
    },
  ];

  const handleTabClick = (title) => {
    setActiveTab(title);
    handleProfileTitle(title)
  };
useEffect(() => {
  // console.log({ activeTab });
  // if(activeTab === "Manage Subscription"){
    if(activeTab === t("profile.profileList.0")){
    handleProfileTitle(t("sidebar.profile"))
    setShowLogout(true)
  }else{
    setShowLogout(false)
    handleProfileTitle(activeTab)
  }
}, [activeTab])
  return (
    <>
      <section className="profile-tabs">
        <div className="profile-tabs-wrapper section-spacing">
          {/* <CategoriesHeader data={categoriesData} onTabClick={handleTabClick} selected={activeTab}/> */}
          <CategoriesHeader data={categoriesData} onTabClick={handleTabClick} selected={activeTab} activeTab={activeTab}/>
          <div className="profile-tabs-content">
            {activeTab === t("profile.profileList.0") && <Subscription />}
            {activeTab === t("profile.profileList.1") && <AccountDetails />}
            {activeTab === t("profile.profileList.2") && <PasswordUpdate />}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileTabs;
