import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout.jsx";
import { todayDailie } from "../Data/data.js";
import { afterReset } from "../Data/data.js";
import { musicData } from "../Data/data.js";
import { soundScapes } from "../Data/data.js";
import { sleepStories } from "../Data/data.js";
import PageListing from "../components/common/PageListing.jsx";
import CategoriesHeader from "../components/common/CategoriesHeader.jsx";
import { useParams } from "react-router-dom";
import { globalApiCalling } from "../global/index.js";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAudio } from "../components/AudioContext/AudioContext.js";
import { getSingleTrack } from "../Api/helper.js";

const Kids = () => {
  const { favourites, setFavourites } = useAudio();
  const { t } = useTranslation();
  const { name, id } = useParams();
  const [activeTab, setActiveTab] = useState("All");
  const [data, setData] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [featuredTracks, setFeaturedTracks] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isData, setIsData] = useState(false);
  const [activeTabData, setActiveTabData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isHome, setIsHome] = useState(false);
  const { favouriteTrackIds } = useAudio();
  const [titleName, setTitleName] = useState();
  const location = useLocation();

  const extractName = (pathname) => {
    const parts = pathname.split("/");
    if (parts.length > 1) {
      return decodeURIComponent(parts[1]);
    }
    return "";
  };

  useEffect(() => {
    const name = extractName(location.pathname);
    setTitleName(name);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes("Home")) {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
    // return ()
  }, [location.pathname]);

  const getData = async () => {
    let favouriteAlbums = [];
    for (const item of favouriteTrackIds) {
      try {
        const result = await getSingleTrack(item);
        favouriteAlbums.push(result?.data?.data);
      } catch (error) {
        console.error(`Error fetching track with id ${item}:`, error);
      }
    }
    setFavourites(favouriteAlbums);
  };

  useEffect(() => {
    if (isHome) {
      getData();
    }
  }, [favouriteTrackIds, isHome]);

  // console.log("fav::  ", favourites);
  useEffect(() => {
    getData();
  }, [favouriteTrackIds]);

  const handleTabClick = (title) => {
    setActiveTab(title);
  };
  const getCategoryAlbums = async () => {
    try {
      const response = await globalApiCalling(
        setLoading,
        "GET",
        `${process.env.REACT_APP_BASE_URL}category/${id}?limit=10000`,
        null
      );
      if (response?.response?.data?.isSuccess === false) {
        // console.log(response?.response?.data?.message);
        toast.error(response?.response?.data?.message);
        return;
      } else {
        if (response?.data?.data?.categoryData?.subCategories.length > 0) {
          setIsData(true);
          // setIsLoading(false);
        } else {
          setIsData(false);
          // setIsLoading(false);
        }

        if (response?.data?.data) {
          setData([response?.data?.data?.categoryData]);
          // console.log(
          //   "the categories buttons:  ",
          //   response?.data?.data?.categoryData?.subCategories
          // );
          setSubCategoriesData(
            response?.data?.data?.categoryData?.subCategories
          );
          // setIsLoading(false);
          let allAlbums = [];

          // Collect albums from each subcategory
          response?.data?.data?.categoryData?.subCategories?.map(
            (subCategory) => {
              subCategory?.albums?.map((album) => {
                if (album?.is_featured) {
                  allAlbums?.push(album);
                }
              });
            }
          );
          let allTracks = [];
          response?.data?.data?.categoryData?.subCategories?.map(
            (subCategory) => {
              subCategory?.tracks?.map((track) => {
                if (track?.is_featured) {
                  allTracks?.push(track);
                }
              });
            }
          );

          setFeaturedTracks(allTracks);
          // Shuffle the albums array to randomize the selection
          // allAlbums = allAlbums?.sort(() => 0.5 - Math.random());

          // // Select up to 5 albums
          // const selectedAlbums = allAlbums?.slice(0, 5);
          // console.log("selectedAlbums", selectedAlbums);
          // Set the selected albums to the featured state
          setFeatured(allAlbums);
          setIsLoading(false);
        }
        return;
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    setActiveTabData();
    setActiveTab("");
  }, [id]);

  useEffect(() => {
    getCategoryAlbums();
  }, [id, name]);

  useEffect(() => {
    if (activeTab === "All") {
      // const data = subCategoriesData?.filter(
      //   (category) => category.name === activeTab
      // );
      setActiveTabData([]);
    } else if (subCategoriesData?.length > 0) {
      const data = subCategoriesData?.filter(
        (category) => category.name === activeTab
      );
      setActiveTabData(data);
    }
  }, [activeTab, id, name]);

  // const data = [
  //   {
  //     data: todayDailie,
  //     title: "today Dailie",
  //     category: "meditate",
  //   },
  //   {
  //     data: afterReset,
  //     title: "after Reset",
  //     category: "sleep",
  //   },
  //   {
  //     data: musicData,
  //     title: "music Data",
  //     category: "music",
  //   },
  //   {
  //     data: soundScapes,
  //     title: "sound Scapes",
  //     category: "work",
  //   },
  //   {
  //     data: sleepStories,
  //     title: "sleep Stories",
  //     category: "wisdom",
  //   },
  // ];
  // const categoriesData = [
  //   {
  //     title: "All",
  //   },
  //   {
  //     title: "Sleep",
  //   },
  //   {
  //     title: "Anxiety",
  //   },
  //   {
  //     title: "Beginners",
  //   },
  //   {
  //     title: "Stress",
  //   },
  //   {
  //     title: "Work",
  //   },
  //   {
  //     title: "Self-Care",
  //   },
  //   {
  //     title: "With Soundscapes",
  //   },
  //   {
  //     title: "Inner Peace",
  //   },
  // ];
  return (
    <Layout title={titleName} setIsLoading={setIsLoading}>
      {isLoading ? (
        <div style={{ margin: "auto" }} className="loader"></div>
      ) : (
        <>
          {isHome ? (
            <>
              <PageListing
                title={titleName}
                data={subCategoriesData}
                favourites={favourites}
                featured={featured}
                featuredTracks={featuredTracks}
              />
            </>
          ) : (
            <>
              {isData ? (
                <>
                  <CategoriesHeader
                    data={subCategoriesData}
                    onTabClick={handleTabClick}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  {activeTabData?.length > 0 ? (
                    <>
                      <PageListing title={titleName} data={activeTabData} />
                    </>
                  ) : (
                    <>
                      <PageListing
                        title={titleName}
                        data={subCategoriesData}
                        featured={featured}
                        featuredTracks={featuredTracks}
                      />
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    color: "white",
                    marginTop: "90px",
                    fontSize: "24px",
                  }}
                >
                  {t("common.noDataFound")}
                </div>
              )}
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default Kids;
