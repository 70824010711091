import React, { useState } from "react";
import "./CalmOptions.css";
import data from "./data.json";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CalmOptions = ({
  toggleForm,
  questionsData,
  setQuestionsData,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeSlideId, setActiveSlideId] = useState(data.data[0].id);
  // const [questionsData, setQuestionsData] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const handleOptionChange = (option) => {
  //   setQuestionsData((prev) => {
  //     const updatedData = { ...prev };
  //     const currentQuestion = currentSlide.question;

  //     // Initialize array if it doesn't exist
  //     if (!updatedData[currentQuestion]) {
  //       updatedData[currentQuestion] = [];
  //     }

  //     // Add or remove the option from the array
  //     if (updatedData[currentQuestion].includes(option)) {
  //       updatedData[currentQuestion] = updatedData[currentQuestion].filter(
  //         (opt) => opt !== option
  //       );
  //     } else {
  //       updatedData[currentQuestion].push(option);
  //     }

  //     return updatedData;
  //   });
  //   setSelectedOptions((prev) =>
  //     prev.includes(option)
  //       ? prev.filter((opt) => opt !== option)
  //       : [...prev, option]
  //   );
  // };

  const handleOptionChange = (option) => {
    setQuestionsData((prev) => {
      const updatedData = { ...prev };
      const currentQuestion = currentSlide.question;

      // Initialize array if it doesn't exist
      if (!updatedData[currentQuestion]) {
        updatedData[currentQuestion] = [];
      }

      // Add or remove the option from the array
      if (updatedData[currentQuestion].includes(option)) {
        updatedData[currentQuestion] = updatedData[currentQuestion].filter(
          (opt) => opt !== option
        );
      } else {
        updatedData[currentQuestion].push(option);
      }

      return updatedData;
    });

    // Allow multiple selection only if activeSlideId is 2
    if (activeSlideId === 2) {
      setSelectedOptions((prev) =>
        prev.includes(option)
          ? prev.filter((opt) => opt !== option)
          : [...prev, option]
      );
    } else {
      setSelectedOptions([option]);
    }
  };

  const handleContinue = () => {
    const currentIndex = data.data.findIndex(
      (slide) => slide.id === activeSlideId
    );
    const nextSlide = data.data[currentIndex + 1];
    // console.log({ nextSlide });
    if (nextSlide) {
      setActiveSlideId(nextSlide.id);
    } else {
      toggleForm();
    }
  };

  const handlePrevious = () => {
    const currentIndex = data.data.findIndex(
      (slide) => slide.id === activeSlideId
    );
    const previousSlide = data.data[currentIndex - 1];
    if (previousSlide) {
      setActiveSlideId(previousSlide.id);
    }
  };
  const handleSkip = () => {
    const lastSlide = data.data[data.data.length - 1];
    setActiveSlideId(lastSlide.id);
  };

  const currentSlide = data.data.find((slide) => slide.id === activeSlideId);
  // console.log({ currentSlide });
  const currentIndex = data.data.findIndex(
    (slide) => slide.id === activeSlideId
  );
  // console.log("lenght", currentIndex.length);
  // console.log({ activeSlideId });

  return (
    <div className="calm-options-container">
      {currentSlide && (
        <div className="calm-options-container-wrapper">
          <div className={currentIndex > 0 && "calm-options-skip-iconn"}>
            {currentIndex > 0 && (
              <FaArrowLeft
                className="back-arrow calm-options-skip-icon"
                onClick={handlePrevious}
              />
            )}
          </div>
          <div className="calm-options-wrapper-inner-content">
            <h3 style={{marginBottom: currentSlide.id !== 2 ? "30px" : "0px"}}>
              {t(`questions.${currentSlide.question}`)}
            </h3>
            {/* {console.log("WERTYUI", currentSlide)} */}
            {currentSlide.id === 2 && <h5 style={{color: "black", marginBottom: "30px", marginTop: "10px"}}>Select all that apply</h5>}
            {currentSlide.subQuestion != "" && (
              <p style={{ fontSize: "18px", fontWeight: 400 }}>
                <span>{t(`lockModal.Qns.subWelcome.p1`)}</span>
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>{t(`lockModal.Qns.subWelcome.p2`)}</span>
                <span>{t(`lockModal.Qns.subWelcome.p3`)}</span>
              </p>
            )}
            {currentIndex === 0 && (
              <>
                <span style={{ fontSize: "18px", fontWeight: 400 }}>
                  {t(`lockModal.Qns.start.p1`)}
                </span>{" "}
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {t(`lockModal.Qns.start.p2`)}
                </span>{" "}
                <span style={{ fontSize: "18px", fontWeight: 400 }}>
                  {t(`lockModal.Qns.start.p3`)}
                </span>
              </>
            )}
          </div>

          {currentSlide.options.map((option) => (
            <div
              key={option.label}
              className={`option ${
                selectedOptions.includes(option.label) ? "selected" : ""
              }`}
              onClick={() => handleOptionChange(option.label, currentSlide)}
            >
              <span className="option-icon">{option.icon}</span>{" "}
              <span className="option-text">
                {t(`options.${option.label}`)}
              </span>
            </div>
          ))}
          <br />
          <button className="continue-button" style={{ marginBottom: (currentIndex !== 0 && currentIndex !== 4) ? "100px" : "0px" }} onClick={handleContinue}>
            {t("buttons.continue")}
          </button>
          {(currentIndex === 0 || currentIndex === 4) && (
            <button className="skip-button" style={{ marginBottom: "100px" }} onClick={toggleForm}>
              {t("buttons.skip")}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export const Calm = ({ toggleForm, questionsData, setQuestionsData }) => {
  return (
    <div className="calm-options-containerr">
      <CalmOptions
        toggleForm={toggleForm}
        questionsData={questionsData}
        setQuestionsData={setQuestionsData}
      />
    </div>
  );
};
