import React, { useEffect, useState } from "react";
import { VscSearch } from "react-icons/vsc";
import { LiaSearchSolid } from "react-icons/lia";
import { IoPrismOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../style/header.css";
import { useTranslation } from "react-i18next";
import { useAudio } from "../AudioContext/AudioContext";
import { toast } from "react-toastify";
import SvgComponent from "./SvgComponent";

const Header = ({
  // showSearch,
  parentTitle,
  title,
  handleModal,
  // showScenes,
  sidebarHide,
  hideHader = true,
  isSidebarVisible,
  showLogout,
  isModalOpen
}) => {
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, [isModalOpen]);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [greeting, setGreeting] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [showScenes, setShowScenes] = useState(false);
  const { setHandleStopButton, setUser_id, setFavouriteTrackIds } = useAudio();

  useEffect(() => {
    if (sidebarHide?.current?.children[0]) {
      sidebarHide.current.children[0].style.display = isSidebarVisible
        ? "none"
        : "block";
    }
  }, [isSidebarVisible]);

  useEffect(() => {
    const getGreeting = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        return t("header.Good Morning");
      } else if (hour < 18) {
        return t("header.Good Afternoon");
      } else {
        return t("header.Good Evening");
      }
    };

    setGreeting(getGreeting());
  }, [t]);
  useEffect(() => {
    if (location.pathname.includes("Home")) {
      setShowScenes(true);
      setShowSearch(true);
    } else {
      setShowSearch(false);
      setShowScenes(false);
    }
    // return ()
  }, [location.pathname]);
  const handleLogOut = () => {
    setUser_id(null);
    setHandleStopButton(true);
    setFavouriteTrackIds([]);
    localStorage.removeItem("token");
    localStorage.removeItem("social");
    toast.success("Logged out!");
    navigate("/Home/1");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {hideHader && (
        <section className="calm_header">
          <div
            className={`calm_header_wrapper ${
              showSearch && showScenes ? "calm-header-wrapper-style" : ""
            } ${
              !location.pathname.includes("Home") &&
              localStorage.getItem("token") && !showLogout &&
              "calm_header_wrapper_1fr"
            }`}
          >
           {!window.location.pathname.includes("terms") &&
           <>
           <div className="header_left">

              <p style={{textAlign: "left", fontSize: "27px", fontWeight: 400, marginBottom: parentTitle ? "40px" : "0px"}}>{title != "Home" && parentTitle}</p>
              <p style={{textAlign: "left", fontSize: "23px", fontWeight: 400}}>{title != "Home" ? title : greeting}</p>
            </div>
           </>
           } 
              {localStorage.getItem("token") && showLogout && (
            // <div>
                <div
                  className={`header_btn`}
                  onClick={handleLogOut}
                  style={{ justifySelf: "end" }}
                >
                  <Link to="#">{t("header.logout")}</Link>
                </div>
            // </div>
              )}
            {(showSearch || showScenes || !localStorage.getItem("token")) && (
              <div className="header_right">
                {showSearch && (
                  <div
                    className="header-icon"
                    onClick={() => navigate("/search")}
                  >
                    <LiaSearchSolid />
                  </div>
                )}
                {showScenes && (
                  <div
                    className="header-icon"
                    onClick={() => navigate("/scenes")}
                  >
                    <SvgComponent name="sceneArrow" />
                  </div>
                )}
                {localStorage.getItem("token") ? null : (
                  <div
                    className={`${
                      handleModal ? "hide-sidebar-on-modal" : ""
                    } header_btn`}
                    onClick={handleModal}
                  >
                    <Link to="#">{t("header.login")}</Link>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Header;
