// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-subscription-title h2{
    background: #EEF0F5;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 1rem 1.5rem;
    color: rgb(28, 28, 28);
    font-weight: 700;
    font-size: 1.25rem;
}
.manage-subscription-details{
    padding: 1rem 1.5rem;
    background-color: #D5D7E2;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.manage-subscription-details p{
   color: #000;
   font-size: 18px;
   line-height: 26px;
   text-align: justify;
}
.manage-subscription-details button{
    margin: 3rem 0 0.8rem 0;

}
.profile-tabs{
    padding-right: 3rem;
}
.profile-tabs-content{
    padding-top: 2rem;
}
.manage-subscription-details button{
    padding:0.6rem 2rem;
}
.manage-subscription-details button:hover{
    color: #000;
    border: 1px solid #000;
}


@media(max-width:767px){
    .profile-tabs{
        padding-right: 0.8rem;
    }
    .manage-subscription-details p{
        text-align: left;
     }
}`, "",{"version":3,"sources":["webpack://./src/components/style/subscription.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,4BAA4B;IAC5B,6BAA6B;IAC7B,oBAAoB;IACpB,sBAAsB;IACtB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,oBAAoB;IACpB,yBAAyB;IACzB,+BAA+B;IAC/B,gCAAgC;AACpC;AACA;GACG,WAAW;GACX,eAAe;GACf,iBAAiB;GACjB,mBAAmB;AACtB;AACA;IACI,uBAAuB;;AAE3B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,sBAAsB;AAC1B;;;AAGA;IACI;QACI,qBAAqB;IACzB;IACA;QACI,gBAAgB;KACnB;AACL","sourcesContent":[".manage-subscription-title h2{\n    background: #EEF0F5;\n    border-top-left-radius: 16px;\n    border-top-right-radius: 16px;\n    padding: 1rem 1.5rem;\n    color: rgb(28, 28, 28);\n    font-weight: 700;\n    font-size: 1.25rem;\n}\n.manage-subscription-details{\n    padding: 1rem 1.5rem;\n    background-color: #D5D7E2;\n    border-bottom-left-radius: 16px;\n    border-bottom-right-radius: 16px;\n}\n.manage-subscription-details p{\n   color: #000;\n   font-size: 18px;\n   line-height: 26px;\n   text-align: justify;\n}\n.manage-subscription-details button{\n    margin: 3rem 0 0.8rem 0;\n\n}\n.profile-tabs{\n    padding-right: 3rem;\n}\n.profile-tabs-content{\n    padding-top: 2rem;\n}\n.manage-subscription-details button{\n    padding:0.6rem 2rem;\n}\n.manage-subscription-details button:hover{\n    color: #000;\n    border: 1px solid #000;\n}\n\n\n@media(max-width:767px){\n    .profile-tabs{\n        padding-right: 0.8rem;\n    }\n    .manage-subscription-details p{\n        text-align: left;\n     }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
