import React, { useContext } from "react";
// import { AudioContext } from './AudioContext';
import "../style/audioControls.css"; // Default styles
import { useAudio } from "../AudioContext/AudioContext";
import SvgComponent from "./SvgComponent";

const AudioControls = () => {
  const {
    AudioPisPlaying,
    AudioPcurrentTime,
    AudioPduration,
    AudioPplayPause,
    AudioPstop,
    AudioPforward,
    AudioPbackward,
    AudioPchangeCurrentTime,
    handleStop
  } = useAudio();
  const handleProgressChange = (event) => {
    const newTime = event.target.value;
    AudioPchangeCurrentTime(newTime);
  };
  const calculatePercentage = () => {
    return AudioPduration ? (AudioPcurrentTime / AudioPduration) * 100 : 0;
  };
  return (
    <div className={`audio-controls `}>
      <div className="line-buttons">
        <div className="topbar">
          <button className="control-button" onClick={AudioPbackward}>
            <SvgComponent name="previousVolume" />
          </button>

          <button
            className="control-button pause-play-button"
            onClick={AudioPplayPause}
          >
            {AudioPisPlaying ? (
              <SvgComponent name="pauseVolume" />
            ) : (
              <SvgComponent name="playVolume" />
            )}
          </button>

          <button className="control-button" onClick={AudioPforward}>
            <SvgComponent name="nextVolume" />
          </button>
        </div>

        <button
          className="stop-button control-button"
          onClick={() => {
            AudioPstop();
            handleStop();
          }}
        >
          <SvgComponent name="hideTrack" />
        </button>
      </div>

      <div className="progress-bar">
        <span className="time-display">
          {Math.floor(AudioPcurrentTime / 60)}:
          {Math.floor(AudioPcurrentTime % 60)
            .toString()
            .padStart(2, "0")}
        </span>
        <input
          type="range"
          value={AudioPcurrentTime}
          max={AudioPduration}
          onChange={handleProgressChange}
          className="progress-input"
          style={{
            background: `linear-gradient(to right, #00a6d6 ${calculatePercentage()}%, #fff ${calculatePercentage()}%)`,
          }}
        />
        <span className="time-display end-time">
          {Math.floor(AudioPduration / 60)}:
          {Math.floor(AudioPduration % 60)
            .toString()
            .padStart(2, "0")}
        </span>
      </div>
    </div>
  );
};

export default AudioControls;
