import React, { useState } from "react";
import "../style/contact.css";
import { sendEmail } from "../../Api/helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const isFormValid = () => {
    const { firstName, lastName, email, message } = formData;
    return firstName && lastName && email && message;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const body = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      userEmail: formData.email,
      message: formData.message,
    };
    const result = await sendEmail(body);
    if (result?.data?.isSuccess) {
      toast.success(result?.data?.message);
      navigate("/Home/1");
    }
  };

  return (
    <Layout>
      <div className="contact-form-container container" style={{ marginLeft: "-1rem"}}>
        <section className="plenum-contact">
          <div className="centered_item">
            <div className="text_width">
              <span className="heading">{t("contactForm.heading")}</span>
              <span className="text">
                {t("contactForm.text1")}
                <br />
                {t("contactForm.text2")}
                <br />
                {t("contactForm.text3")}
              </span>
              <span className="text">{t("contactForm.text4")}</span>
            </div>
          </div>
        </section>
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "670px",
            }}
          >
            <div className="form-group">
              <label htmlFor="first-name" style={{ color: "white" }}>
                {t("contactForm.firstName")}
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name" style={{ color: "white" }}>
                {t("contactForm.lastName")}
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" style={{ color: "white" }}>
                {t("contactForm.email")}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message" style={{ color: "white" }}>
                {t("contactForm.message")}
              </label>
              <textarea
                id="message"
                name="message"
                onChange={handleChange}
                style={{ borderRadius: "4px" }}
                required
              ></textarea>
            </div>
            <button
              disabled={isSubmitted || !isFormValid()}
              type="submit"
              className={!isFormValid() ? "disabled" : ""}
              style={{marginBottom: "50px"}}
            >
              {isSubmitted
                ? t("contactForm.submitting")
                : t("contactForm.submit")}
            </button>
          </form>
        </section>
      </div>
    </Layout>
  );
};
export default ContactForm;
