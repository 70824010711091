import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { GetEncryptedSingleUser, getFavouritesById, UpdateUser } from "../../Api/helper";
import { jwtDecode } from "jwt-decode";

const AudioContext = createContext();

export const useAudio = () => {
  return useContext(AudioContext);
};

export const AudioProvider = ({ children }) => {
  const audioPlayerRef = useRef(new Audio());
  const audioPlayerRefTrack = useRef(new Audio());
  const saved = JSON.parse(localStorage.getItem("isChecked"));
  const bgImage = localStorage.getItem("bgImage");
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1.0);

  const [isPlaying, setIsPlaying] = useState(false);
  const [audioDetails, setAudioDetails] = useState(null);
  const [authorDetails, setAuthorDetails] = useState(null);
  const [albumDetails, setAlbumDetails] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [handleStopButton, setHandleStopButton] = useState(false);
  const [favouriteTrackIds, setFavouriteTrackIds] = useState([]);
  const [user_id, setUser_id] = useState();
  const [backgroundImage, setBackgroundImage] = useState(bgImage || null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [ChildTimeoutId, setChildTimeoutId] = useState(null);
  const isChecked = useRef(saved || false);
  const [isIdle, setIsIdle] = useState(false);
  const [timeoutIds, setTimeoutIds] = useState([]);
  const [favourites, setFavourites] = useState();
  const timeoutIdRef = useRef(null);
  const childTimeoutIdRef = useRef(null);
  const [navActive, setNavActive] = useState("Home");

  const [AudioPisPlaying, setAudioPisPlaying] = useState(false);
  const [AudioPcurrentTime, setAudioPcurrentTime] = useState(0);
  const [AudioPduration, setAudioPduration] = useState(0);
  const [AudioPaudioSource, setAudioPaudioSource] = useState(null);
  const AudioPaudioRef = useRef(new Audio());
  const shouldPlayPause = useRef(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const getData = async () => {
      if (token) {
        const decoded = jwtDecode(token);
        const user_id = decoded?.user?.id;
        let tokenData = jwtDecode(token);

        const cancelTimestamp = tokenData?.user?.cancel_date;
        if (cancelTimestamp) {
          const cancelDate = new Date(cancelTimestamp * 1000);
          const currentDate = new Date();

          if (cancelDate <= currentDate) {
            tokenData.user.subscription_status = "inactive";
            const body = new FormData();
            body.append("subscription_status", "inactive");
            const updateUser = await UpdateUser(body, user_id);
            // console.log("update request   ",updateUser);
            const result = await GetEncryptedSingleUser(user_id);
            // console.log("GetEncryptedSingleUser   ", result);
            if (result) {
              localStorage.setItem("token", result?.data?.data);
            }
          }
        }
      }
    };
    getData();
  });

  useEffect(() => {
    if (backgroundImage) {
      const bodyStyles = document.body.style;
      bodyStyles.backgroundImage = `linear-gradient(
        to bottom, 
        #075072b1, 
        #075072cc
      ), url(${backgroundImage})`;
      bodyStyles.backgroundSize = "cover";
      bodyStyles.backgroundAttachment = "fixed";
      bodyStyles.backgroundPosition = "center";
      bodyStyles.backgroundRepeat = "no-repeat";

      const styleElement = document.createElement("style");
      styleElement.id = "dynamic-background-style";
      document.head.appendChild(styleElement);

      const setBackgroundForMobile = () => {
        const existingStyleElement = document.getElementById(
          "dynamic-background-style"
        );
        if (existingStyleElement) {
          if (window.innerWidth <= 767) {
            existingStyleElement.textContent = `
              body::before {
                content: "";
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                z-index: -1;
                background-image: linear-gradient(
                  to bottom, 
                  #075072b1, 
                  #075072cc
                ), url(${backgroundImage});
              }
            `;
          } else {
            existingStyleElement.textContent = "";
          }
        }
      };

      setBackgroundForMobile();
      window.addEventListener("resize", setBackgroundForMobile);

      return () => {
        window.removeEventListener("resize", setBackgroundForMobile);
        if (styleElement) {
          document.head.removeChild(styleElement);
        }
      };
    }
  }, [backgroundImage]);

  useEffect(() => {
    // console.log(isChecked.current,"isChecked");
    if (isChecked.current) {
      localStorage.setItem("isChecked", JSON.stringify(isChecked.current));
    } else {
      localStorage.removeItem("isChecked");
    }
  }, [isChecked.current]);

  useEffect(() => {
    const clearAllTimeouts = () => {
      // timeoutIds.forEach(id => clearTimeout(id));
      // setTimeoutIds([]);
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
      if (childTimeoutIdRef.current) {
        clearTimeout(childTimeoutIdRef.current);
        childTimeoutIdRef.current = null;
      }
    };

    const handleMouseMove = async () => {
      await clearAllTimeouts();
      setIsIdle(false);
      // if (timeoutId) {
      //   console.log("timeoutId", timeoutId, isIdle)
      //   setIsIdle(false);
      //   clearTimeout(timeoutId);
      //   setTimeoutId(null);
      // }
      // if (ChildTimeoutId) {
      //   setIsIdle(false);
      //   console.log("ChildTimeoutId", ChildTimeoutId, isIdle)
      //   clearTimeout(ChildTimeoutId);
      //   setChildTimeoutId(null)
      // }
      document.getElementById("root").style.display = "block";
      if (backgroundImage) {
        document.body.style.backgroundImage = `linear-gradient(
            to bottom, 
            #075072b1, 
            #075072cc
          ), url(${backgroundImage})`;
      }
      setIsIdle(true);

      if (backgroundImage) {
        timeoutIdRef.current = setTimeout(() => {
          // console.log("isIdle", isIdle , backgroundImage , isChecked.current)
          childTimeoutIdRef.current = setTimeout(() => {
            if (isIdle && backgroundImage && isChecked.current === true) {
              // console.log("coming in 5")
              document.getElementById("root").style.display = "none";
              document.body.style.backgroundImage = `url(${backgroundImage})`;
            }
          }, 10000);
          // setTimeoutIds(prev => [...prev, childTimeoutId]);
        }, 5000); // 10 seconds of inactivity

        // setTimeoutIds(prev => [...prev, newTimeoutId]);
      }
    };
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mousedown", handleMouseMove);
    window.addEventListener("scroll", handleMouseMove);
    window.addEventListener("touchstart", handleMouseMove);
    window.removeEventListener("keypress", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mousedown", handleMouseMove);
      window.removeEventListener("scroll", handleMouseMove);
      window.addEventListener("keypress", handleMouseMove);
      window.removeEventListener("touchstart", handleMouseMove);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (ChildTimeoutId) {
        clearTimeout(ChildTimeoutId);
      }
    };
  }, [isIdle, backgroundImage]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      const user_id = decoded?.user?.id;
      setUser_id(user_id);
    }
  }, []);
  // console.log("in context file, ", user_id);

  const getData = async (user_id) => {
    if (user_id) {
      const body = { user_id };
      const result = await getFavouritesById(body);
      if (result?.data?.data) {
        setFavouriteTrackIds(result?.data?.data);
      }
    }
  };

  useEffect(() => {
    user_id && getData(user_id);
  }, [user_id]);

  useEffect(() => {
    const audio = AudioPaudioRef.current;

    const updateAudioPcurrentTime = () =>
      setAudioPcurrentTime(audio.currentTime);
    const updateAudioPduration = () => setAudioPduration(audio.duration);
    const handleAudioPend = () => {
      setAudioPisPlaying(false);
      setAudioPcurrentTime(0);
    };

    audio.loop = true; // Set the audio to loop

    if (AudioPaudioSource) {
      audio.src = AudioPaudioSource;
      audio.load();
    }

    audio.addEventListener("timeupdate", updateAudioPcurrentTime);
    audio.addEventListener("loadedmetadata", updateAudioPduration);
    audio.addEventListener("ended", handleAudioPend);

    return () => {
      audio.removeEventListener("timeupdate", updateAudioPcurrentTime);
      audio.removeEventListener("loadedmetadata", updateAudioPduration);
      audio.removeEventListener("ended", handleAudioPend);
    };
  }, [AudioPaudioSource]);

  useEffect(() => {
    if (shouldPlayPause.current) {
      AudioPplayPause();
      shouldPlayPause.current = false; // Reset the flag
    }
  }, [AudioPaudioSource]);

  const AudioPplayPause = () => {
    if (AudioPisPlaying) {
      AudioPaudioRef.current.pause();
    } else {
      AudioPaudioRef.current.play();
    }
    setAudioPisPlaying(!AudioPisPlaying);
  };

  const AudioPstop = () => {
    AudioPaudioRef.current.pause();
    AudioPaudioRef.current.currentTime = 0;
    setAudioPisPlaying(false);
    setAudioPcurrentTime(0);
  };

  const AudioPforward = () => {
    AudioPaudioRef.current.currentTime = Math.min(
      AudioPaudioRef.current.currentTime + 15,
      AudioPduration
    );
  };

  const AudioPbackward = () => {
    AudioPaudioRef.current.currentTime = Math.max(
      AudioPaudioRef.current.currentTime - 15,
      0
    );
  };

  const AudioPchangeAudioSource = (source) => {
    setAudioPaudioSource(source);
    setAudioPcurrentTime(0);
    // shouldPlayPause.current = true;
    setTimeout(() => {
      shouldPlayPause.current = true;
      setAudioPisPlaying(true);
      AudioPaudioRef.current.play();
    }, 2000);
  };

  const AudioPchangeCurrentTime = (time) => {
    AudioPaudioRef.current.currentTime = time;
    setAudioPcurrentTime(time);
  };
  //--------------------------------------------------------------
  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handleStop = () => {
    setIsPlaying(false);
  };

  const playAudio = (track) => {
    handleStop();
    AudioPstop();
    setHandleStopButton(true);
    setIsPlaying(false);
    if (currentTrack?.file_path !== track?.file_path) {
      audioPlayerRef.current.src = track?.file_path;
      setCurrentTrack(track);
    }
    audioPlayerRef.current.play();
  };

  const pauseAudio = () => {
    audioPlayerRef.current.pause();
  };

  const stopAudio = () => {
    audioPlayerRef.current.pause();
    audioPlayerRef.current.currentTime = 0;
  };

  const muteAudio = () => {
    audioPlayerRef.current.muted = true;
    setIsMuted(true);
  };

  const unmuteAudio = () => {
    audioPlayerRef.current.muted = false;
    setIsMuted(false);
  };

  const setFullVolume = () => {
    audioPlayerRef.current.volume = 1.0;
    setVolume(1.0);
  };

  const changeVolume = (newVolume) => {
    audioPlayerRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  const value = {
    currentTrack,
    isMuted,
    volume,
    playAudio,
    pauseAudio,
    stopAudio,
    muteAudio,
    unmuteAudio,
    setFullVolume,
    changeVolume,
    isPlaying,
    setIsPlaying,
    audioDetails,
    setAudioDetails,
    authorDetails,
    setAuthorDetails,
    albumDetails,
    setAlbumDetails,
    handlePlay,
    handleStop,
    currentTime,
    audioPlayerRef,
    currentTime,
    setCurrentTime,
    handleStopButton,
    setHandleStopButton,
    audioPlayerRefTrack,
    favouriteTrackIds,
    setFavouriteTrackIds,
    getData,
    user_id,
    setUser_id,
    backgroundImage,
    setBackgroundImage,
    isChecked,
    setFavourites,
    favourites,
    navActive,
    setNavActive,
    AudioPisPlaying,
    AudioPcurrentTime,
    AudioPduration,
    AudioPplayPause,
    AudioPstop,
    AudioPforward,
    AudioPbackward,
    AudioPchangeAudioSource,
    AudioPchangeCurrentTime,
    AudioPaudioSource,
    setAudioPisPlaying,
  };

  return (
    <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
  );
};
