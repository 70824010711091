import React, { useState, useRef, useEffect } from "react";
import "../style/search.css";
import Layout from "../layout/Layout";
import { VscSearch } from "react-icons/vsc";
import { TbClockHour5Filled } from "react-icons/tb";
import { AiFillThunderbolt } from "react-icons/ai";
import Multiselect from "multiselect-react-dropdown";
import { IoIosArrowDown } from "react-icons/io";
import { globalApiCalling } from "../../global";
import { toast } from "react-toastify";
import Card from "./Card";
import LockModal from "./authModal/LockModal";
import { useTranslation } from "react-i18next";
import { getAllTracks } from "../../Api/helper";

const SearchComponent = () => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [duration, setDuration] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showLockModal, setLockShowModal] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const categoryDropdownRef = useRef(null);
  const timeMultiselectRef = useRef(null);
  const [allTracks, setAllTracks] = useState([]);

  // Function to toggle dropdown visibility
  // const toggleDropdown = (containerClass, outside) => {
  //   // Construct the selector for the option list container
  //   const dropdownElement = document.querySelector(`.${containerClass} .optionListContainer`);
  //   console.log("dropdownElement", dropdownElement)
  //   if (dropdownElement) {
  //     // Check the current class and toggle it
  //     console.log("dropdownElement.classList.contains('displayNone'", dropdownElement.classList.contains('displayNone'), "dropdownElement.classList.contains('displayBlock'", dropdownElement.classList.contains('displayBlock'));
  //     if (dropdownElement.classList.contains('displayBlock')) {
  //       dropdownElement.classList.remove('displayBlock');
  //       dropdownElement.classList.add('displayNone');
  //     } else if (dropdownElement.classList.contains('displayNone') && !outside){
  //       dropdownElement.classList.remove('displayNone');
  //       dropdownElement.classList.add('displayBlock');
  //     }
  //   }
  // };
  // const handleClickOutside = (event) => {
  //   if (categoryDropdownRef.current && !categoryDropdownRef.current.contains(event.target)) {
  //     toggleDropdown("category-drop-down", true)
  //   }
  //   if (timeMultiselectRef.current && !timeMultiselectRef.current.contains(event.target)) {
  //     toggleDropdown("time-multiselect", true)

  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);
  const handleLockModal = () => {
    setLockShowModal(!showLockModal);
    if (showLockModal) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const trendingData = [
    {
      key: "happiness",
    },
    {
      key: "anxiety",
    },
    {
      key: "rain",
    },
    {
      key: "soundscapes",
    },
    {
      key: "sleep meditations",
    },
  ];
  const getAllCategories = async () => {
    try {
      const response = await globalApiCalling(
        setLoading,
        "GET",
        `${process.env.REACT_APP_BASE_URL}category/all?limit=10000`,
        null
      );
      if (response?.response?.data?.isSuccess === false) {
        // console.log(response?.response?.data?.message);
        toast.error(response?.response?.data?.message);
        return;
      } else {
        if (response?.data?.data?.rows) {
          // console.log(response?.data?.data?.rows, "iygeofueuofuewife");
          setAllData(response?.data?.data?.rows);
          const categoryNames = response?.data?.data?.rows
            ?.filter((row) => row.parent_category_id !== null)
            ?.map((row) => ({
              key: row.name,
            }));

          setCategoriesOptions(categoryNames);
          const albums = response?.data?.data?.rows?.map((row) => ({
            key: row.albums,
          }));
          setTracks(albums);
        }
        return;
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getAllCategories();
    const recent = localStorage.getItem("recent");
    if (recent) {
      setRecentSearches(JSON.parse(recent));
    }
  }, []);

  useEffect(() => {
    const recent = JSON.stringify(recentSearches?.slice(0, 5));
    localStorage.setItem("recent", recent);
  }, [recentSearches]);

  useEffect(() => {
    const getData = async () => {
      const result = await getAllTracks();
      setAllTracks(result?.data?.data);
    };
    getData();
  }, []);

  const handleInputChange = (e) => {
    setSearchInput(e?.target?.value);
    // setSearchInitiated(false);
  };

  const handleSearch = (searchTerm = searchInput) => {
    // console.log("searchTerm searchterm", searchTerm);
    if (searchTerm?.trim() !== "") {
      // let matchedTracks = tracks
      //   ?.flatMap((trackGroup) => trackGroup?.key)
      //   ?.filter((track) =>
      //     track?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      //   );
      let matchedTracks = tracks
        ?.flatMap((trackGroup) => {
          // console.log("Track Group Key:", trackGroup?.key);
          return trackGroup?.key;
        })
        ?.filter((track) => {
          // console.log(
          //   "Track Title:",
          //   track?.title,
          //   "track?.category?.name",
          //   track?.category?.name,
          //   track?.category?.name
          //     ?.toLowerCase()
          //     ?.includes(searchTerm?.toLowerCase())
          // );
          return (
            track?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
            track?.category?.name
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase())
          );
        });
      setSearchInitiated(true);
      if (matchedTracks.length > 0) {
        // console.log("matchedTracks in searchterm", matchedTracks);
        setSearchResults(matchedTracks);
        // setRecentSearches((prev) => [...prev, searchTerm]);
        // setRecentSearches((prev) => [
        //   ...prev.slice(Math.max(prev.length - 5, 0)),
        // ]);
      } else {
        setSearchResults([]);
      }
      // setSearchInput("");
    } else if (
      duration?.length === 0 &&
      categories?.length === 0 &&
      searchTerm?.trim() === ""
    ) {
      // setSearchInitiated(true);
    }
    if (duration?.length || categories?.length) {
      let matchedTracks = tracks?.flatMap((trackGroup) => trackGroup?.key);

      if (duration?.length) {
        const getTimeInSeconds = (timeString) => {
          if (timeString?.includes("+")) {
            return parseInt(timeString) * 60; // handle "60+ min" as 60 minutes
          }
          if (timeString == "0") {
            return 0;
          }
          return parseInt(timeString) * 60;
        };

        const getTimeRange = (duration, options) => {
          const index = options.findIndex(
            (option) => option?.key === duration?.key
          );
          let prevOption;
          if (index === 0) {
            prevOption = {
              key: "0",
            };
          } else {
            prevOption = options[index - 1];
          }

          const minTime = prevOption
            ? getTimeInSeconds(prevOption?.key)
            : getTimeInSeconds(duration?.key);

          // Special handling for "60+ min"
          if (duration?.key?.includes("+")) {
            return [minTime, Infinity];
          }

          const nextOption = options[index + 1];
          const maxTime = nextOption
            ? getTimeInSeconds(nextOption?.key)
            : getTimeInSeconds(duration?.key);

          return [minTime, maxTime];
        };

        if (duration?.length || categories?.length) {
          if (duration?.length) {
            let collectionTracks = [];
            matchedTracks = allTracks.filter((track) => {
              return duration.some((duration) => {
                const [minTime, maxTime] = getTimeRange(
                  duration,
                  categoriesTimeOptions
                );

                // return track?.author?.tracks?.some((track) => {
                
                  setSearchInitiated(true);
                  if (track?.duration > minTime && track?.duration < maxTime) {
                    collectionTracks.push(track);
                  }
                // });
              });
            });

            matchedTracks = collectionTracks;
          }
        }
        // matchedTracks = matchedTracks.filter((track) => {
        //   return duration?.some((duration) => {
        //     // const [durationValue, unit] = duration;
        //     const durationInSeconds = parseInt(duration.key) * 60;
        //     console.log(track);
        //     return track?.author?.tracks?.some((track) => {
        //       console.log("track.duration", track.duration, durationInSeconds);
        //       return track.duration === durationInSeconds;
        //     });
        //   });
        // });
      }
      if (categories?.length) {
        const newMatchedTracks = allData?.filter((cat) =>
          categories.some(
            (item) => cat?.name?.toLowerCase() === item?.key?.toLowerCase()
          )
        );
        if (newMatchedTracks?.length) {
          matchedTracks = matchedTracks?.flatMap((item) =>
            newMatchedTracks.flatMap((first) =>
              first?.albums?.flatMap((com) =>
                com?.id === item?.id ? [item] : []
              )
            )
          );
        } else {
          matchedTracks = [];
        }
        // console.log({ matchedTracks });
      }
      if (matchedTracks?.length > 0) {
        // console.log("hello check this one", { matchedTracks });
        setSearchInitiated(true);
        setSearchResults(matchedTracks);
      } else {
        setSearchInitiated(true);
        setSearchResults([]);
      }
      // setSearchInput("");
    }
  };
  function findCategoriesByTrackDuration(categories, targetDuration) {
    return categories?.filter((category) =>
      category?.albums?.some((album) =>
        album?.tracks?.some((track) => track.duration === targetDuration)
      )
    );
  }
  const handleRecentSearchClick = (searchTerm, category) => {
    // console.log("searchTerm, category", searchTerm, category);
    category ? setSearchInput(searchTerm?.key) : setSearchInput(searchTerm);
    if (categories?.length > 0) {
      // const updateSelectedCategory = [...categories, searchTerm];
      // setCategories(updateSelectedCategory);
      setCategories((prev) =>
        prev.includes(searchTerm) ? prev : [...prev, searchTerm]
      );
    } else {
      category && setCategories([searchTerm]);
    }
    !category && handleSearch(searchTerm);
    // !category && setSearchInitiated(true);
  };

  const searchData = [
    { title: "happiness" },
    { title: "anxiety" },
    { title: "erik braa" },
    { title: "harry styles" },
  ];

  const [timeOptions, setTimeOptions] = useState([
    { label: "Past 24 hours", checked: false },
    { label: "Past week", checked: false },
    { label: "Past month", checked: false },
  ]);

  const categoriesTimeOptions = [
    {
      cat: "Group 1",
      key: "3 min",
    },
    {
      cat: "Group 1",
      key: "5 min",
    },
    {
      cat: "Group 1",
      key: "10 min",
    },
    {
      cat: "Group 2",
      key: "15 min",
    },
    {
      cat: "Group 2",
      key: "20 min",
    },
    {
      cat: "Group 2",
      key: "30 min",
    },
    {
      cat: "Group 2",
      key: "45 min",
    },
    {
      cat: "Group 2",
      key: "60+ min",
    },
  ];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setShowDropdown(true);
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClear = () => {
    setSearchResults([]);
    setSearchInitiated(false);
    setCategories([]);
    setDuration([]);
    setSearchInput("");
  };

  const handleSelectCategory = (selectedList, selectedItem) => {
    setCategories(selectedList);
    // console.log("Selected List: ", selectedList);
    // console.log("Selected Item: ", selectedItem);
  };

  const handleRemoveCategory = (selectedList, removedItem) => {
    setCategories(selectedList ? selectedList : []);
    // console.log("Selected List: ", selectedList);
    // console.log("Selected Item: ", selectedItem);
  };

  const handleSelectDuartion = (selectedList, selectedItem) => {
    // console.log("selectedList", selectedList);
    setDuration(selectedList);
  };

  const handleRemoveDuaration = (selectedList, removedItem) => {
    setDuration(selectedList ? selectedList : []);
    // console.log("Selected List: ", selectedList);
  };

  // console.log({ duration });
  useEffect(() => {
    // console.log({
    //   duration,
    //   categories,
    //   searchInput,
    //   key: searchInput?.trim() === "",
    // });
    if (duration?.length > 0 || categories?.length > 0 || searchInput) {
      handleSearch();
      // setSearchInitiated(true);
    } else if (
      duration?.length === 0 &&
      categories?.length === 0 &&
      searchInput.trim() === ""
    ) {
      setSearchResults([]);
      // setSearchInitiated(true);
    }
  }, [duration, categories]);

  return (
    <Layout title={t("search.title")}>
      <div className="search-container">
        <div className="search-bar">
          <div className="search-bar-wrapper">
            <VscSearch />
            <input
              type="text"
              value={searchInput}
              onChange={handleInputChange}
              placeholder={t("search.placeholder")}
              className="search-input"
            />
          </div>
          <button
            onClick={() => {
              handleSearch();
              if (searchInput) {
                if (searchInput) {
                  setRecentSearches((prev) =>
                    prev.includes(searchInput)
                      ? prev
                      : [...prev.slice(-4), searchInput]
                  );
                }
              }
            }}
            className="btn-primary"
          >
            {t("search.button")}
          </button>
        </div>

        <div className="dropdown-category">
          <div
            ref={categoryDropdownRef}
            className={
              categories?.length > 0
                ? "active-selection cursor-pointer"
                : "cursor-pointer"
            }
          >
            {/* <div onClick={(e) => e.stopPropagation()}> */}
            <Multiselect
              displayValue="key"
              hideSelectedList
              onKeyPressFn={function noRefCheck() {}}
              onRemove={handleRemoveCategory}
              onSearch={function noRefCheck() {}}
              onSelect={handleSelectCategory}
              options={categoriesOptions}
              placeholder={t("search.categoryPlaceholder")}
              showCheckbox
              selectAllText={t("search.categoryPlaceholder")}
              showArrow={true}
              customArrow={<IoIosArrowDown />}
              onSelectAll={false}
              searchable={false}
              onInput={(e) => e.preventDefault()}
              onFocus={(e) => e.target.blur()}
              className="category-drop-down"
              control="false"
              selectedValues={categories}
            />
            {/* </div> */}
          </div>
          <div
            ref={timeMultiselectRef}
            className={
              duration?.length > 0
                ? "active-selection cursor-pointer"
                : "cursor-pointer"
            }
          >
            {/* <div onClick={(e) => e.stopPropagation()}> */}
            <Multiselect
              displayValue="key"
              hideSelectedList
              onKeyPressFn={function noRefCheck() {}}
              onRemove={handleRemoveDuaration}
              onSearch={function noRefCheck() {}}
              onSelect={handleSelectDuartion}
              options={categoriesTimeOptions}
              placeholder={t("search.timePlaceholder")}
              showCheckbox
              selectAllText={t("search.duration")}
              showArrow={true}
              className="time-multiselect"
              customArrow={<IoIosArrowDown />}
              onSelectAll={false}
              searchable={false}
              onInput={(e) => e.preventDefault()}
              onFocus={(e) => e.target.blur()}
              onClick={(e) => {
                e.target.focus();
              }}
              selectedValues={duration}
            />
            {/* </div> */}
          </div>
          {/* {searchResults.length > 0 && ( */}
          {(searchResults.length > 0 || searchInitiated) && (
            <button
              onClick={handleClear}
              style={{
                marginLeft: "20px",
                background: "none",
                border: "none",
                color: "white",
                cursor: "pointer",
              }}
            >
              <span style={{ cursor: "pointer", margin: 0 }}>
                {t("search.clearAll")}
              </span>
            </button>
          )}
        </div>
        {searchInitiated ? (
          <div className="results">
            <div className="results-wrapper">
              <AiFillThunderbolt />
              <h3>{t("search.results")}</h3>
            </div>
            <div
              className="detail_container"
              // className="results-content"
              // style={{
              //   display: "flex",
              //   flexDirection: "row",
              //   marginTop: "20px",
              // }}
            >
              {searchResults?.length > 0 ? (
                searchResults?.map((track) => (
                  // <button
                  //   style={{
                  //     background: "none",
                  //     border: "none",
                  //     width: "180px",
                  //     display: "flex",
                  //     marginRight: "30px",
                  //   }}
                  //   className="results-inner-content"
                  //   key={track.id}
                  // >
                  //   {console.log("here11111: ", track)}
                  //   {/* <p>{track.title}</p> */}
                  //   <Card
                  //     data={track}
                  //     cardHeight="190px"
                  //     handleLockModal={handleLockModal}
                  //     category={track?.category?.name}
                  //     reSize={true}
                  //   />
                  // </button>
                  <div
                    key={track?.id}
                    style={{ minWidth: `calc(100% / ${4})` }}
                  >
                    <Card
                      data={track}
                      category={track?.category?.name}
                      handleLockModal={handleLockModal}
                      cardHeight="190px"
                    />
                  </div>
                ))
              ) : (
                <p>{t("search.noResults")}</p>
              )}
            </div>
          </div>
        ) : (
          <>
            {recentSearches.length > 0 && (
              <div className="results">
                <div className="results-wrapper">
                  <TbClockHour5Filled />
                  <h3>{t("search.recent")}</h3>
                </div>
                <div className="results-content">
                  {recentSearches?.map((data) => (
                    <button
                      onClick={() => handleRecentSearchClick(data)}
                      style={{ background: "none", border: "none" }}
                      className="results-inner-content"
                      key={data}
                    >
                      <p style={{ fontSize: "16px", fontWeight: 400 }}>
                        {data}
                      </p>
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className="results">
              <div className="results-wrapper">
                <AiFillThunderbolt />
                <h3>{t("search.trending")}</h3>
              </div>
              <div className="results-content">
                {trendingData?.map((data) => (
                  <button
                    onClick={() => handleRecentSearchClick(data, true)}
                    style={{ background: "none", border: "none" }}
                    className="results-inner-content"
                    key={data.key}
                  >
                    <p style={{ fontSize: "16px", fontWeight: 400 }}>
                      {data.key}
                    </p>
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      {/* <button> click me </button> */}
      {showLockModal && (
        <div
          className={`auth-modal-overlay ${
            showLockModal ? "user-modal-overlay" : ""
          }`}
        >
          <LockModal showLockModal={handleLockModal} />
        </div>
      )}
    </Layout>
  );
};

export default SearchComponent;
