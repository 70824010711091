import React, { useState, useEffect } from "react";
import { FaGlasses } from "react-icons/fa";
import { TbUserHeart } from "react-icons/tb";
import { BsCalendarDate } from "react-icons/bs";
import { BsStopwatch } from "react-icons/bs";
import "../style/profile.css";
import Auth from "../common/authModal/Auth";
import { useNavigate } from "react-router-dom";
import ProfileList from "./ProfileList";
import ProfileTabs from "./ProfileTabs";
import { useTranslation } from "react-i18next";

const UserProfile = ({
  showProfile,
  setShowProfile,
  showTabs,
  setShowTabs,
  handleProfileTitle,
  profileTitle,
  setShowLogout
}) => {
  const [profileModal, setProfileModal] = useState(false);
  const { t, i18n } = useTranslation();
  const [token, setToken] = useState(null);
  const handleModal = () => {
    setProfileModal(!profileModal);
  };
  const handleToggle = () => {
    setShowProfile(false);
    setShowTabs(false);
  };
  const handleTabsToggle = () => {
    setShowTabs(true);
    setShowProfile(false);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
      setShowProfile(false);
      setShowTabs(false);
    }
  }, [profileModal]);

  return (
    <>
      {showProfile && !showTabs && (
        <section className={`user-profile section-spacing`}>
          {!token &&
            <div className="user-user-profile-wrapper">
              <div className="user-profile-icons">
                <FaGlasses />
                <TbUserHeart />
                <BsCalendarDate />
                <BsStopwatch />
              </div>
              <div className="user-profile-content">
                <h2>
                {t("profile.title")}
                </h2>
                <button
                  className="btn-primary user-profile-btn"
                  onClick={handleModal}
                >
                  {t("profile.Sign up or Log in")}
                </button>
              </div>
            </div>
          }

          {profileModal && (
            <div
              className={`auth-modal-overlay ${
                profileModal ? "user-modal-overlay" : ""
              }`}
            >
              <Auth handleModal={handleModal} />
            </div>
          )}
        </section>
      )}
      {!showProfile && !showTabs && <ProfileList handleProfileTitle={handleProfileTitle} setShowLogout={setShowLogout}/>}
      {!showProfile && showTabs && <ProfileTabs />}
      {/* {!showProfile && !showTabs && ( */}
      {/* {token ? (
        showProfile ? (
          <div className="profile-btn-wrapper">
            <button
              className="btn-primary user-profile-btn profile-btn-1"
              onClick={handleToggle}
            >
              User Profile
            </button>
            <button
              className="btn-primary user-profile-btn profile-btn-1"
              onClick={handleTabsToggle}
            >
              User Profile Tabs
            </button>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )} */}
    </>
  );
};

export default UserProfile;
