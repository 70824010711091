import axios from "axios";

const config = {
  headers: {
    Authorization: `Bearer ${"token"}`,
  },
};

export const GetAllScenes = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}scene/all`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch scenes:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching scenes data:", error);
  }
};

export const GetSingleUser = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}user/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch user:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

export const UpdateUser = async (body, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}user/${id}`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to update user:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error updating user data:", error);
  }
};

export const ChangePassword = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}user/change-password`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to update password:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error updating user password:", error);
  }
};

export const getTermAndConditions = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}policy/get?category=terms and conditions`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error(
        "Failed to fetch Terms and Conditions:",
        response?.data?.message
      );
    }
  } catch (error) {
    console.error("Error fetching Terms and Conditions:", error);
  }
};

export const getPrivacyPolicy = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}policy/get?category=policy`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch Privacy Policy:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching Privacy Policy:", error);
  }
};

export const toggleFavouriteTrack = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}user/favorites`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to add favourite track:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error adding favourite track:", error);
  }
};

export const getFavouritesById = async (body) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}user/get-favourite-by-id`,
      {
        params: {
          user_id: body.user_id,
        },
      },
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to get favourite track:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error getting favourite track:", error);
  }
};

export const getSingleTrack = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}track/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to get favourite track:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error getting favourite track:", error);
  }
};

export const sendEmail = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}email/send-email`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to send Email:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error sending Email:", error);
  }
};

export const getPaymentDetail = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}subscription/price-detail`,
      {
        // ...config,
        headers: {
          // ...config.headers,
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        },
      }
      
    );
    // console.log("the response ::::    ", response)
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to get payment data:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error getting payment data:", error);
  }
};

export const createSubscription = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}subscription/create`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to create subscription:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error creating subscription:", error);
  }
};

export const GetEncryptedSingleUser = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}user/encrypt/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch user:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

export const cancelSubscription = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}subscription/cancel`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to cancel subscription:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error cancel subscription:", error);
  }
};

export const getAllTracks = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}track/all?page=1&limit=10000`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to get all tracks:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error getting all tracks:", error);
  }
};