import React from 'react'
import  ContactForm  from '../components/contact/Contact.jsx'

const Contact = () => {
  return (
    <div><ContactForm /></div>
  )
}

export default Contact;
