import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../common/Header.jsx";
import Sidebar from "../common/Sidebar.jsx";
import "../style/layout.css";
import Auth from "../common/authModal/Auth.jsx";
import { globalApiCalling } from "../../global/index.js";
import { toast } from "react-toastify";

const Layout = ({
  children,
  showSearch,
  parentTitle,
  title,
  showScenes,
  setPath,
  showQuiz,
  hideHader = true,
  setShowProfile,
  setShowTabs,
  showLogout = false,
  setIsLoading
}) => {
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [leftNavData, setLeftNavData] = useState([]);
  const sidebarHide = useRef();
  // const [render, setRender] = useState(false);

  const handleModal = () => {
    setisModalOpen(!isModalOpen);
    // setIsSidebarVisible(!isSidebarVisible);
    const token = localStorage.getItem("token")
    if (setShowProfile && token) {
      setShowProfile(false);
      setShowTabs(false);
    }
  };
  const getAllCategories = async () => {
    try {
      const response = await globalApiCalling(
        setLoading,
        "GET",
        `${process.env.REACT_APP_BASE_URL}category/all?limit=10000`,
        null
      );
      if (response?.response?.data?.isSuccess === false) {
        // console.log(response?.response?.data?.message);
        toast.error(response?.response?.data?.message);
        return;
      } else {
        // setRender(true);
        // console.log(response?.data?.data?.rows, "ifeiufeufi");

        if (response?.data?.data?.rows) {
          setLeftNavData(response?.data?.data?.rows);
          // localStorage.setItem(
          //   "menu",
          //   JSON.stringify(response?.data?.data?.rows)
          // );
          sessionStorage.setItem(
            "menu",
            JSON.stringify(response?.data?.data?.rows)
          );
          // toast.success(response?.data?.message);
        }
        // handleModal();
        return;
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    // console.log({ leftNavData });
    const menu = sessionStorage.getItem("menu");
    // console.log({ leftNavData, length: menu?.length });
    if (leftNavData?.length === 0 && menu?.length > 0) {
      setLeftNavData(JSON.parse(sessionStorage.getItem("menu")));
    } else {
      getAllCategories();
    }
  }, []);
  // useEffect(() => {
  //   if (leftNavData.length) {
  //     localStorage.setItem("menu", leftNavData);
  //   }
  // }, [leftNavData]);

  return (
    <div className={`clam_layout`}>
      <div
        className={` ${isModalOpen ? "blur" : ""}`}
        ref={sidebarHide}
      >
        <Sidebar setPath={setPath} leftNavData={leftNavData} isModalOpen={isModalOpen} setisModalOpen={setisModalOpen} setIsLoading={setIsLoading} />
      </div>
      <div
        className={`layout_rigth ${
          isModalOpen ? "blur" : ""
        } layout_rigth_full`}
      >
        <div className="layout_right_child">
          {hideHader && (
            <Header
              showSearch={showSearch}
              parentTitle={parentTitle}
              title={title}
              handleModal={handleModal}
              showScenes={showScenes}
              showQuiz={showQuiz}
              sidebarHide={sidebarHide}
              // isSidebarVisible={isSidebarVisible}
              hideHader={true}
              showLogout={showLogout}
              isModalOpen={isModalOpen}
            />
          )}
          {children}
        </div>
      </div>
      {isModalOpen && <Auth handleModal={handleModal} />}
    </div>
  );
};

export default Layout;
