import React, { useContext, useEffect, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "../style/audioPlayer.css";
import { CiHeart } from "react-icons/ci";
import SvgComponent from "./SvgComponent";
import { useNavigate } from "react-router-dom";
import { useAudio } from "../AudioContext/AudioContext";
import { use } from "i18next";
import { getFavouritesById, toggleFavouriteTrack } from "../../Api/helper";
import { jwtDecode } from "jwt-decode";
import AudioControls from "./AudioControls";

const MusicPlayer = () => {
  const {
    audioDetails,
    handlePlay,
    handleStop,
    currentTime,
    getCurrentTime,
    audioPlayerRefTrack,
    setCurrentTime,
    isPlaying,
    setIsPlaying,
    handleStopButton,
    favouriteTrackIds,
    setFavouriteTrackIds,
    user_id,
    AudioPaudioSource,
    AudioPchangeAudioSource
  } = useAudio();
  // console.log("audiodetailssssss    ", audioDetails);
  // const [favouriteTrackIds, setFavouriteTrackIds] = useState([]);
  const [heartStyle, setHeartStyle] = useState("rgba(255, 255, 255, 0.2)");
  const navigate = useNavigate();
  // useEffect(() => {
  //   const token = localStorage.getItem("token") || null;
  //   const decoded = token ? jwtDecode(token) : null;
  //   const get_user_id = decoded?.user?.id;
  // }, []);
  const formatDuration = (duration) => {
    if (duration < 60) {
      return `${duration} seconds`;
    } else if (duration < 3600) {
      // less than 1 hour
      const minutes = Math.floor(duration / 60);
      const seconds = duration % 60;
      return seconds > 0 ? `${minutes} minutes ${seconds} seconds` : `${minutes} minutes`;
    } else {
      // 1 hour or more
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = duration % 60;
      let result = `${hours} hours`;
      if (minutes > 0) {
        result += ` ${minutes} minutes`;
      }
      if (seconds > 0) {
        result += ` ${seconds} seconds`;
      }
      return result;
    }
  };
  const handleHeartStyle = async () => {
    setHeartStyle((prevColor) =>
      prevColor === "rgba(255, 255, 255, 0.2)"
        ? "rgb(255, 89, 89)"
        : "rgba(255, 255, 255, 0.2)"
    );

    const body = {
      track_id: audioDetails?.id,
      user_id: user_id,
    };
    const result = await toggleFavouriteTrack(body);
    if (!result?.data?.isSuccess) {
      console.log("error is as:  ", result?.data?.message);
    } else {
      const updatedFavouriteTrackIds = favouriteTrackIds.includes(
        audioDetails?.id
      )
        ? favouriteTrackIds.filter((id) => id !== audioDetails?.id)
        : [...favouriteTrackIds, audioDetails?.id];
      setFavouriteTrackIds(updatedFavouriteTrackIds);
    }
  };
  useEffect(() => {
    const audioPlayer = document.querySelector("audio");
    audioPlayer && setCurrentTime(audioPlayer.currentTime);
  }, [audioDetails?.source, currentTime]);

  useEffect(() => {
    if (favouriteTrackIds.includes(audioDetails?.id)) {
      // console.log("list", favouriteTrackIds, "1234567890   ", audioDetails);
      setHeartStyle("rgb(255, 89, 89)");
    } else {
      setHeartStyle("rgba(255, 255, 255, 0.2)");
    }
  }, [favouriteTrackIds, audioDetails]);
  return (
    !handleStopButton &&
    isPlaying && (
      <section className="music-player-container">
        {audioDetails?.source ? (
          <div
            className="music-player-wrapper"
            onClick={() => {
              handleStop();
              navigate(`/details/music/${audioDetails?.id}`, {
                state: { audioDetails, currentTime },
              });
            }}
          >
            <div className="music-player-content">
              <div className="music-player-image">
                <img src={audioDetails?.image} alt="" />
              </div>
              <div className="music-player-inner-contennt">
              <p>{formatDuration(audioDetails?.duration)}</p>
                <h4>{audioDetails?.name}</h4>
              </div>
            </div>
            <div
              className="music-player-audio"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {isPlaying && (<>{!AudioPaudioSource && AudioPchangeAudioSource(audioDetails?.source)}</>)}
                  {/* <AudioPlayer
                    autoplay
                    loop
                    ref={audioPlayerRefTrack}
                    autoPlay
                    src={audioDetails?.source}
                    onPlay={() =>
                      (audioPlayerRefTrack.current.audio.current.currentTime =
                        currentTime + 1)
                    }
                    onPause={() => audioPlayerRefTrack?.current?.pause}
                    onListen={(currentTime) => setCurrentTime(currentTime)}
                    onSeeked={(e) => {
                      // console.log("e.target.currentTime", e.target.currentTime);
                      setCurrentTime(e.target.currentTime);
                    }}
                    progressJumpSteps={{ backward: 15000, forward: 15000 }}
                    customIcons={{
                      play: <SvgComponent name="playVolume" />,
                      pause: <SvgComponent name="pauseVolume" />,
                      rewind: <SvgComponent name="previousVolume" />,
                      forward: <SvgComponent name="nextVolume" />,
                    }}
                  /> */}
                  <AudioControls />
              {/* <div
                className="hide-volum-track"
                onClick={(e) => {
                  e.stopPropagation();
                  handleStop();
                  setCurrentTime(0);
                  navigate("/Home/1");
                }}
              >
                <SvgComponent name="hideTrack" />
              </div> */}
            </div>

            <div
              className="music-player-heart"
              style={{ backgroundColor: heartStyle }}
              onClick={(e) => {
                e.stopPropagation();
                handleHeartStyle();
              }}
            >
              <CiHeart />
            </div>
          </div>
        ) : (
          <></>
        )}
      </section>
    )
  );
};

export default MusicPlayer;
