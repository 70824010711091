import React, { useState, useEffect } from "react";
import "../style/auth.css";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import { GetSingleUser, UpdateUser } from "../../Api/helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AccountDetails = () => {
  const { t, i18n } = useTranslation();
  const [social, setSocial] = useState();

  useEffect(() => {
    const data = localStorage.getItem("social");
    setSocial(data);
  }, [])

  const [formValues, setFormValues] = useState({
    firstName: "",
    email: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    email: "",
  });

  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const token = localStorage.getItem("token");
  const decoded = jwtDecode(token);
  const userId = decoded.user.id;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case "firstName":
        errors.firstName = value ? "" : t("profile.Account Details.Name Error");
        break;
      case "email":
        const emailPattern =
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        errors.email = value
          ? emailPattern.test(value)
            ? ""
            : t("profile.Account Details.Email Error")
          : t("profile.Account Details.Email Error");
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const validate = () => {
    const errors = {
      firstName: formValues.firstName
        ? ""
        : t("profile.Account Details.Old Email Error"),
      email: formValues.email
        ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
            formValues.email
          )
          ? ""
          : t("profile.Account Details.Email Error")
        : t("profile.Account Details.Email Error"),
    };

    setFormErrors(errors);
    return Object.keys(errors).every((key) => !errors[key]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const body = new FormData();
      body.append("first_name", formValues?.firstName);
      body.append("email", formValues?.email);
      const response = await UpdateUser(body, userId);
      
      // console.log("12345678", response);
      if (response?.data?.isSuccess) {
        toast.success(response?.data?.message);
        navigate("/Home/1")
      }
      else {
        toast.error(response?.data?.message)
      }
      if (response == undefined) {
        toast.error("Unable to update!");
      }
    }
  };

  useEffect(() => {
    const isValid =
      Object.values(formValues).every((value) => value) && validate();
    setIsFormValid(isValid);
  }, [formValues]);

  useEffect(() => {
    const getUserData = async () => {
      const response = await GetSingleUser(userId);
      setFormValues({
        firstName: response.data.data.first_name,
        email: response.data.data.email,
      });
    };

    getUserData();
  }, []);

  return (
    <div className="form-container update-password">
      <div className="update-password-wrapper">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              // type="email"
              name="firstName"
              placeholder={t("profile.Account Details.First Name")}
              className={`form-control ${formErrors.firstName ? "error" : ""}`}
              value={formValues.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {formErrors.firstName && (
              <p className="error-message">{formErrors.firstName}</p>
            )}
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder={t("profile.Account Details.New email")}
              className={`form-control ${formErrors.email ? "error" : ""}`}
              value={formValues.email}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={social == "true"}
            />
            {formErrors.email && (
              <p className="error-message">{formErrors.email}</p>
            )}
          </div>

          <button type="submit" className="submit-btn" disabled={!isFormValid}>
            {t("profile.Account Details.Update")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AccountDetails;
