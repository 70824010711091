// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_rigth{
    padding:0rem 0 0rem 5.1rem;
    margin-left: 200px;
}
.layout_left.blur, .layout_rigth.blur{
    filter: blur(3px);
}
.layout_rigth_full{
    height: 100vh;
    /* height: 100%; */
    /* padding-bottom: 0 !important; */
  }
  .layout_right_child{
    height: 100%;
    /* overflow-y: auto; */
  }
  @media screen and (max-width: 1440px) {
    .layout_rigth{
      padding: 0rem 0 0rem 4.1rem;
        }
  }
@media screen and (max-width: 1024px) {
    .layout_rigth{
        margin-left: 0px;
        padding: 5.5rem 0 0 1rem;
    }
  }

@media (max-width: 767px) {
    .layout_rigth {
        padding: 0rem 0 0 0.8rem;
    }
    .layout_right_child{
      padding: 6rem 0 0rem 0;
      /* height: 100%;
      overflow-y: auto; */
    }
   
  }
`, "",{"version":3,"sources":["webpack://./src/components/style/layout.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,kCAAkC;EACpC;EACA;IACE,YAAY;IACZ,sBAAsB;EACxB;EACA;IACE;MACE,2BAA2B;QACzB;EACN;AACF;IACI;QACI,gBAAgB;QAChB,wBAAwB;IAC5B;EACF;;AAEF;IACI;QACI,wBAAwB;IAC5B;IACA;MACE,sBAAsB;MACtB;yBACmB;IACrB;;EAEF","sourcesContent":[".layout_rigth{\n    padding:0rem 0 0rem 5.1rem;\n    margin-left: 200px;\n}\n.layout_left.blur, .layout_rigth.blur{\n    filter: blur(3px);\n}\n.layout_rigth_full{\n    height: 100vh;\n    /* height: 100%; */\n    /* padding-bottom: 0 !important; */\n  }\n  .layout_right_child{\n    height: 100%;\n    /* overflow-y: auto; */\n  }\n  @media screen and (max-width: 1440px) {\n    .layout_rigth{\n      padding: 0rem 0 0rem 4.1rem;\n        }\n  }\n@media screen and (max-width: 1024px) {\n    .layout_rigth{\n        margin-left: 0px;\n        padding: 5.5rem 0 0 1rem;\n    }\n  }\n\n@media (max-width: 767px) {\n    .layout_rigth {\n        padding: 0rem 0 0 0.8rem;\n    }\n    .layout_right_child{\n      padding: 6rem 0 0rem 0;\n      /* height: 100%;\n      overflow-y: auto; */\n    }\n   \n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
