// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail_container{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 26px;
    padding: 2.5rem 3rem 14.5rem 0;
}
.detail_container .card-img img{
    max-height: 189px;
}
@media (max-width: 1024px) {
    .detail_container{
        grid-template-columns: repeat(3, 1fr);
        padding: 2.5rem 0.8rem 14.5rem 0;

    }
}

@media (max-width: 767px) {
    .detail_container{
        grid-template-columns: repeat(2, 1fr);
        padding: 2rem 0.8rem 4rem 0;
        gap:0.8rem;
    }
}

.detail_container .card_inner_content p {
    text-align: left;
    width: -webkit-fill-available;
}`, "",{"version":3,"sources":["webpack://./src/components/style/detail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,8BAA8B;AAClC;AACA;IACI,iBAAiB;AACrB;AACA;IACI;QACI,qCAAqC;QACrC,gCAAgC;;IAEpC;AACJ;;AAEA;IACI;QACI,qCAAqC;QACrC,2BAA2B;QAC3B,UAAU;IACd;AACJ;;AAEA;IACI,gBAAgB;IAChB,6BAA6B;AACjC","sourcesContent":[".detail_container{\n    display: grid;\n    grid-template-columns: repeat(6, 1fr);\n    gap: 26px;\n    padding: 2.5rem 3rem 14.5rem 0;\n}\n.detail_container .card-img img{\n    max-height: 189px;\n}\n@media (max-width: 1024px) {\n    .detail_container{\n        grid-template-columns: repeat(3, 1fr);\n        padding: 2.5rem 0.8rem 14.5rem 0;\n\n    }\n}\n\n@media (max-width: 767px) {\n    .detail_container{\n        grid-template-columns: repeat(2, 1fr);\n        padding: 2rem 0.8rem 4rem 0;\n        gap:0.8rem;\n    }\n}\n\n.detail_container .card_inner_content p {\n    text-align: left;\n    width: -webkit-fill-available;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
