import React from "react";
import PrivacyPolicyList from "../components/privacyPolicy/PrivacyPolicy";

const PrivacyPolicy = () => {
  return (
    <div>
      <PrivacyPolicyList />
    </div>
  );
};

export default PrivacyPolicy;
