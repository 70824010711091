import React, { lazy, Suspense, useState } from "react";
// import MainPage from "./pages/Home.jsx";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
// import SeeDeatils from "./pages/SeeDeatils.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AudioProvider } from "./components/AudioContext/AudioContext.js";
// const MainPage = lazy(() => import('./pages/Home.jsx'));
// const SeeDeatils = lazy(() => import('./pages/SeeDeatils.jsx'));
// const AlbumDetails = lazy(() => import('./pages/AlbumDetails.jsx'));
// const Search = lazy(() => import('./components/common/Search.jsx'));
// const Scenes = lazy(() => import('./pages/Scenes.jsx'));
// const Profile = lazy(() => import('./pages/Profile.jsx'));
// const Kids = lazy(() => import('./pages/Kids.jsx'));
// const LockModal = lazy(() => import('./components/common/authModal/LockModal.jsx'));
// const ProfileList = lazy(() => import('./components/profile/ProfileList.jsx'));
// const SignUpQuiz = lazy(() => import('./pages/SignUpQuiz.jsx'));
// const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy.jsx'));
// const TermsCondition = lazy(() => import('./pages/TermsCondition.jsx'));
// const Payment = lazy(() => import('./components/payment/Payment.jsx'));
// const AlbumMusic = lazy(() => import('./components/albumDetail/AlbumMusic.jsx'));
// const GoogleAuth = lazy(() => import('./components/common/authModal/google.jsx'));
// const MusicPlayer = lazy(() => import('./components/common/MusicPlayer.jsx'));
// const Checkout = lazy(() => import('./pages/Checkout.jsx'));
// const Contact = lazy(() => import('./pages/Contact.jsx'));
// import MainPage from './pages/Home.jsx';
import SeeDeatils from './pages/SeeDeatils.jsx';
import AlbumDetails from './pages/AlbumDetails.jsx';
import Search from './components/common/Search.jsx';
import Scenes from './pages/Scenes.jsx';
import Profile from './pages/Profile.jsx';
import Kids from './pages/Kids.jsx';
import LockModal from './components/common/authModal/LockModal.jsx';
import ProfileList from './components/profile/ProfileList.jsx';
import SignUpQuiz from './pages/SignUpQuiz.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import TermsCondition from './pages/TermsCondition.jsx';
import Payment from './components/payment/Payment.jsx';
import AlbumMusic from './components/albumDetail/AlbumMusic.jsx';
import GoogleAuth from './components/common/authModal/google.jsx';
import MusicPlayer from './components/common/MusicPlayer.jsx';
// import Checkout from './pages/Checkout.jsx';
import Contact from './pages/Contact.jsx';


function App() {
  const { pathname } = useLocation();
  let path = pathname.replace(/\//g, "");
  
  return (
    <AudioProvider>
        {/* <Suspense 
        fallback={<div style={{ margin: "auto", marginTop: "50px" }} className="loader"></div>}
        > */}
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          <Route path="/" element={<Navigate to="/Home/1" replace />} />
          <Route path="/see-all/:title/:id" element={<SeeDeatils />} />
          <Route path="/details/:album_id" element={<AlbumDetails />} />
          <Route path="/details/music/:id" element={<AlbumMusic />} />
          <Route path="/search" element={<Search />} />
          <Route path="/scenes" element={<Scenes />} />
          <Route path="/google" element={<GoogleAuth />} />

          {/* <Route path="/meditate/:id" element={<Meditate />} />
        <Route path="/sleep/:id" element={<Sleep />} />
        <Route path="/music/:id" element={<Music />} />
        <Route path="/work/:id" element={<Work />} />
        <Route path="/wisdom/:id" element={<Wisdom />} />
        <Route path="/movement/:id" element={<Movement />} /> */}
          <Route path="/:name/:id" element={<Kids />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/user-profile" element={<ProfileList />} />
          <Route path="/lockModal" element={<LockModal />} />
          <Route path="/quiz" element={<SignUpQuiz />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsCondition />} />
          <Route path="/new-member-offer" element={<Payment />} />
          {/* <Route path="/checkout" element={<Checkout/>}/> */}
          <Route path="/contact" element={<Contact />} />
        </Routes>
        
        <MusicPlayer />
      </div>
      {/* </Suspense> */}
    </AudioProvider>
  );
}

export default App;
