import React, { useEffect, useState } from "react";
import "../style/categories.css";
import { useLocation } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const CategoriesHeader = ({
  selected,
  data,
  onTabClick,
  selectedOption,
  activeTab,
  setActiveTab,
  // handleChange,
}) => {
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState(
    // selected ? selected : data[0]?.title
    activeTab || "All"
  );
  const [selectedValues, setSelectedValues] = useState([]);
  // console.log({data, selectedOption, selected});
  const categoriesData = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Sleep",
    },
    {
      id: 3,
      title: "Anxiety",
    },
    {
      id: 4,
      title: "Beginners",
    },
    {
      id: 5,
      title: "Stress",
    },
    {
      id: 6,
      title: "Work",
    },
    {
      id: 7,
      title: "Self-Care",
    },
    {
      id: 8,
      title: "With Soundscapes",
    },
    {
      id: 9,
      title: "Inner Peace",
    },
    {
      id: 10,
      title: "Focus",
    },
    {
      id: 11,
      title: "Emotions",
    },
    {
      id: 12,
      title: "Less Guidance",
    },
    {
      id: 13,
      title: "Relationships",
    },
    {
      id: 14,
      title: "Personal Growth",
    },
    {
      id: 15,
      title: "Kids",
    },
    {
      id: 16,
      title: "By Guest Instructors",
    },
  ];
  // console.log("activeTab", activeTab)
  // useEffect(() => {
  //   setActiveCategory("All")
  // }, [data])
  const handleChange = (e) => {
    // console.log("e.target.value", e.target.value)
    setActiveCategory(e.target.value);
    onTabClick(e.target.value);
  };
  const handleTabClick = (title) => {
    setActiveCategory(title);
    onTabClick(title);
  };

  return (
    <>
      <div
        className="categories-container showed-on-web"
        style={{ marginRight: "10px" }}
      >
        {!window.location.pathname.includes("profile") && (
          <button
            // key={category?.id}
            onClick={() => handleTabClick("All")}
            className={`category-button ${activeTab === "All" ? "active" : ""}`}
          >
            All
          </button>
        )}
        {data?.map((category) => (
          <button
            key={category?.id}
            onClick={() =>
              handleTabClick(category?.title ? category?.title : category?.name)
            }
            className={`category-button ${
              category?.title
                ? category?.title === activeTab
                  ? "active"
                  : ""
                : category?.name === activeTab
                ? "active"
                : ""
            }`}
          >
            {category?.title || category?.name}
          </button>
        ))}
      </div>

      {/* ============================= Showed on Mobile DropDown Categories ============================ */}

      <section className="dropdown-categories-options showed-on-mbl">
        <div className="dropdown-categories-options-wrapper">
          <select
            className="dropdown-select"
            value={activeTab}
            onChange={handleChange}
          >
            {!location.pathname.includes("profile") && (
              <option value="All">All</option>
            )}
            {data?.map((option, index) => (
              <option key={index} value={option.title || option.name}>
                {option.title || option.name}
              </option>
            ))}
          </select>
          <MdOutlineKeyboardArrowDown
            style={{
              position: "absolute",
              top: "50%",
              right: "27px",
              transform: "translateY(-50%)",
              fontSize: "26px",
              color: "#fff",
              pointerEvents: 'none',
            }}
          />
        </div>
      </section>
    </>
  );
};

export default CategoriesHeader;
