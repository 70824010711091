import React, { useState, useEffect } from "react";
import "../../style/auth.css";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { FaFacebookSquare } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa6";
import { Calm } from "../../startQuiz";
import { useTranslation } from "react-i18next";
import { globalApiCalling } from "../../../global";
import { toast } from "react-toastify";
import {  useGoogleLogin } from "@react-oauth/google";
import GoogleAuth from "./google";
import axios from "axios";
import { LoginSocialFacebook } from "reactjs-social-login";
import { jwtDecode } from "jwt-decode";
import { useAudio } from "../../AudioContext/AudioContext";
import { useNavigate } from "react-router-dom";

const Auth = ({ handleModal }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [data, setData] = useState(null);
  const [formValues, setFormValues] = useState({
    firstName: "",
    email: "",
    password: "",
  });
  const [questionsData, setQuestionsData] = useState([]);
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    email: "",
    password: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [quiz, setQuiz] = useState(false);
  const { setUser_id } = useAudio();

  const navigate = useNavigate();
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleQuiz = () => {
    setQuiz(true);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  // console.log({ questionsData });
  const validateField = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case "firstName":
        if (!isLogin) {
          errors.firstName = value ? "" : t("lockModal.Error.name");
        }
        break;
      case "email":
        const emailPattern =
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        errors.email = value
          ? emailPattern.test(value)
            ? ""
            : t("lockModal.Error.email")
          : t("lockModal.Error.email");
        break;
      case "password":
        errors.password = value
          ? value.length >= 8
            ? ""
            : t("lockModal.Error.Password Length")
          : t("lockModal.Error.Password Length");
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const validate = () => {
    const errors = {
      firstName:
        isLogin || formValues.firstName ? "" : t("lockModal.Error.name"),
      email: formValues.email
        ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
            formValues.email
          )
          ? ""
          : t("lockModal.Error.email")
        : t("lockModal.Error.email"),
      password: formValues.password
        ? formValues.password.length >= 8
          ? ""
          : t("lockModal.Error.Password Length")
        : t("lockModal.Error.Password Length"),
    };

    setFormErrors(errors);
    return Object.keys(errors).every((key) => !errors[key]);
  };

  // useEffect(() => {
  //   GoogleOAuthProvider.initialize({
  //     client_id:
  //       "1044865408584-i4m5cqhtgv0m43np0ufi37s9lpdp3dg4.apps.googleusercontent.com",
  //     callback: handleCredentialResponse,
  //   });
  // }, []);

  // function handleCredentialResponse(response) {
  //   console.log("Encoded JWT ID token: " + response.credential);
  // }
  function localizeQuestionData(questionData) {
    const localizedData = {};
    Object.keys(questionData).forEach(questionKey => {
      localizedData[t(`questions.${questionKey}`)] = questionData[questionKey].map(option => {
        return t(`options.${option}`); // Get original option value using t()
      });
    });
    return localizedData;
  }
  const handleSubmit = async (e) => {
    try {
      setRequest(true);
      // console.log("e.nativeEvent.submitter.name", e.nativeEvent.submitter.name);
      e.preventDefault();
      if (e.nativeEvent.submitter.name == "Log_In") {
        if (validate()) {
          const formData = {
            email: formValues.email,
            password: formValues.password,
          };
          const response = await globalApiCalling(
            setLoading,
            "POST",
            `${process.env.REACT_APP_BASE_URL}user/login`,
            formData
          );
// console.log("response?.data", response)
          if (response?.data?.isSuccess === false) {
            // console.log(response?.data?.message);
            toast.error(response?.data?.message);
            setRequest(false)
            return;
          } else if(response?.response?.data?.isSuccess === false) {
            console.error(response?.response?.data?.message);
            toast.error(response?.response?.data?.message);
            setRequest(false)
            return;} else {
            // console.log("response at login::::",response?.data);
            toast.success(response?.data?.message);
            localStorage.setItem("token", response?.data?.data);
            sessionStorage.removeItem("menu");
            const token = response?.data?.data;
            const decoded = jwtDecode(token);
            const user_id = decoded?.user?.id;
            const subscriptionStatus = decoded?.user?.subscription_status
            setUser_id(user_id);
            localStorage.setItem("social", false);
            if (subscriptionStatus != "active") {
              navigate('/new-member-offer');
            }
            handleModal();
            return;
          }
        }
        setRequest(false);
      } else {
        let updateQuestionsData
        if(questionsData){
          updateQuestionsData = await localizeQuestionData(questionsData)
          // console.log(updateQuestionsData, "from signup");
        }
        if (validate()) {
          const formData = {
            first_name: formValues.firstName,
            last_name: formValues.firstName,
            username: formValues.firstName,
            email: formValues.email,
            password: formValues.password,
            phone: "090078601",
            quiz: questionsData ? updateQuestionsData : null,
          };
          const response = await globalApiCalling(
            setLoading,
            "POST",
            `${process.env.REACT_APP_BASE_URL}user/create`,
            formData
          );
          // console.log("responseresponse", response);
          if (response?.response?.data?.isSuccess === false) {
            // console.log(response?.response?.data?.message);
            toast.error(response?.response?.data?.message);
            setRequest(false)
            return;
          } else {
            localStorage.setItem("token", response?.data?.data?.token);
            toast.success(response?.data?.message);
            handleModal();
            navigate("/new-member-offer");
            return;
          }
        //   if (!isLogin) {
        //     formData.firstName = formValues.firstName;
        //   }
        //   console.log("Form submitted successfully", formData);
        }
        setRequest(false);
      }
    } catch (err) {
      // console.log("err", err);
    }
  };

  useEffect(() => {
    const isValid = isLogin
      ? formValues.email && formValues.password.length >= 8
      : Object.values(formValues).every((value) => value) && validate();
    setIsFormValid(isValid);
  }, [formValues, isLogin]);

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setFormValues({
      firstName: "",
      email: "",
      password: "",
    });
    setFormErrors({
      firstName: "",
      email: "",
      password: "",
    });
    setIsFormValid(false);
    setQuiz(false);
  };
  const credentials = async (userData) => {
    try {
      // console.log("User Data::", userData);
      const data = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userData.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
            Accept: "application/json",
          },
        }
      );
      // console.log("data.data", data?.data);
      if (data?.data) {
        try {
          // console.log("User Data::", data?.data);
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}user/google-auth`,
            {...data?.data, method: "google"}
          );
          // console.log("response.data", response?.data?.data?.token);
          if (response?.data?.data?.token) {
            toast.success("Login Successful");
            localStorage.setItem("token", response?.data?.data?.token);
            sessionStorage.removeItem("menu");
            const token = response?.data?.data?.token;
            const decoded = jwtDecode(token);
            const user_id = decoded?.user?.id;
            setUser_id(user_id);
            handleModal();
          }
          return response.data;
        } catch (error) {
          console.error(error);
          return null;
        }
        // localStorage.setItem("token", response?.data);
        // localStorage.removeItem("menu");
        // handleModal();
      }
      // return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      // console.log({ codeResponse });
      const data = await credentials(codeResponse);
      // console.log({data});
      if (data.isSuccess) {
        localStorage.setItem("social", true);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });
  return (
    <div className="form-main-wrapper auth-modal-overlay">
      <div className="signup-form-container">
        <div className="form-close-icon" onClick={handleModal}>
          <RxCross2 />
        </div>
        <h2>
          {!quiz
            ? isLogin
              ? t("lockModal.account")
              : t("lockModal.create profile")
            : ""}
        </h2>

        {!quiz ? (
          <div className="form-content-wrapper">
            <form onSubmit={handleSubmit}>
              {!isLogin && (
                <div className="form-group">
                  <input
                    type="text"
                    name="firstName"
                    placeholder={t("lockModal.name")}
                    value={formValues.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {formErrors.firstName && (
                    <span className="error-message">
                      {formErrors.firstName}
                    </span>
                  )}
                </div>
              )}

              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder={t("lockModal.email")}
                  value={formValues.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {formErrors.email && (
                  <span className="error-message">{formErrors.email}</span>
                )}
              </div>

              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder={t("lockModal.password")}
                  value={formValues.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {formErrors.password && (
                  <span className="error-message">{formErrors.password}</span>
                )}
              </div>

              {Object.values(formValues).some((value) => value) && (
                <button
                  type="submit"
                  name={request ? "Processing" : isLogin ? "Log_In" : "Sign_Up"}
                  disabled={request}
                >
                  {request ? "Processing... " : isLogin ? t("lockModal.Log in") : t("lockModal.Sign up")}
                </button>
              )}
            </form>

            <div className="or-divider">{t("lockModal.OR")}</div>

            <div className="social-login">
              {/* <div className="social-btns fb-btn">
                  <FaFacebookSquare />
                  <p>{t("lockModal.facebook")}</p>
                </div> */}
              <LoginSocialFacebook
                appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                onResolve={(response) => {
                  // console.log(response);
                  // setProfile(response.data);
                }}
                onReject={(error) => {
                  // console.log(error);
                }}
              >
                <div className="social-btns fb-btn">
                  <FaFacebookSquare />
                  <p>{t("lockModal.facebook")}</p>
                </div>
              </LoginSocialFacebook>
              <Link to="#">
                <div className="social-btns apple-btn">
                  <FaApple />
                  <p>{t("lockModal.apple")}</p>
                </div>
              </Link>
              {/* <Link to="/google"> */}
              <div
                className="social-btns google-btn"
                onClick={() => googleLogin()}
              >
                <FcGoogle />
                <p>{t("lockModal.google")}</p>
                {/* <GoogleAuth handleModal={handleModal}></GoogleAuth> */}
              </div>
              {/* </Link> */}
            </div>

            <p className="terms-privacy-text">
              {t("lockModal.terms.text 1")}
              <Link to="/terms">{t("lockModal.terms.text 2")}</Link>{" "}
              {t("lockModal.terms.text 3")}{" "}
              <Link to="/privacy-policy">{t("lockModal.terms.text 4")}</Link> ,{" "}
              {t("lockModal.terms.text 5")}
            </p>

            <p className="account-text">
              {isLogin ? (
                <>
                  {t("lockModal.already account")}{" "}
                  <Link to="#" onClick={handleQuiz}>
                    {t("lockModal.Sign up")}
                  </Link>
                </>
              ) : (
                <>
                  {t("lockModal.already account")}{" "}
                  <Link to="#" onClick={toggleForm}>
                    {t("lockModal.Log in")}
                  </Link>
                </>
              )}
            </p>
          </div>
        ) : (
          <Calm
            questionsData={questionsData}
            setQuestionsData={setQuestionsData}
            toggleForm={toggleForm}
          />
        )}
      </div>
    </div>
  );
};

export default Auth;
