import React, { useEffect, useState } from "react";
import "../style/privacyPolicy.css";
import { getPrivacyPolicy } from "../../Api/helper";
import { useTranslation } from "react-i18next";

const PrivacyPolicyList = () => {
  const { t } = useTranslation();
  const [updated, setUpdated] = useState("");
  const [content, setContent] = useState("");
  useEffect(() => {
    const getData = async () => {
      const response = await getPrivacyPolicy();
      // console.log(
      //   "terms and conditions response: ",
      //   response.data.data.content
      // );
      setContent(response?.data?.data?.content);
      const updatedDate = new Date(response?.data?.data?.updated_at);
      const formattedDate = updatedDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      setUpdated(formattedDate);
    };
    getData();
  }, []);
  return (
    <>
      <section className="plenum-privacy-policy">
        <div className="privary-policy-wrapper">
        <h1>{t("privacyPolicy.header")}</h1>
        <span className="privacy-time">{t("privacyPolicy.lastUpdated")}{updated}</span>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyList;
