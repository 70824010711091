import React, { useState } from "react";
import CalmOptions, { Calm } from "../components/startQuiz";

const SignUpQuiz = () => {
  const [questionsData, setQuestionsData] = useState([]);
  return (
    <div>
      <Calm questionsData={questionsData} setQuestionsData={setQuestionsData} />
    </div>
  );
};

export default SignUpQuiz;
