import React from "react";
import TermsConditionList from "../components/termsCondition/TermsConditionList";
import Layout from "../components/layout/Layout";

const TermsCondition = () => {
  return (
    <div>
      <Layout title="">
        <div style={{ marginTop: "70px", marginRight: "13px", display: "flex", justifyContent: "center"  }}>
          <TermsConditionList />
        </div>
      </Layout>
    </div>
  );
};

export default TermsCondition;
