import React, { useState, useEffect } from "react";
import "../style/auth.css";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import { ChangePassword } from "../../Api/helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PasswordUpdate = () => {
  const { t, i18n } = useTranslation();
  const [formValues, setFormValues] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const token = localStorage.getItem("token");
  const decoded = jwtDecode(token);
  const userId = decoded?.user?.id;

  const [formErrors, setFormErrors] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case "oldPassword":
        errors.oldPassword = value
          ? ""
          : t("profile.Change Password.oldPassword Error");
        break;
      case "password":
        errors.password = value
          ? value.length >= 8
            ? ""
            : t("profile.Change Password.password length")
          : t("profile.Change Password.password length");
        break;
      case "confirmPassword":
        errors.confirmPassword =
          value === formValues.password
            ? ""
            : t("profile.Change Password.Passwords don't match");
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const validate = () => {
    const errors = {
      oldPassword: formValues.oldPassword
        ? ""
        : t("profile.Change Password.oldPassword Error"),
      password: formValues.password
        ? formValues.password.length >= 8
          ? ""
          : t("profile.Change Password.password length")
        : t("profile.Change Password.password length"),
      confirmPassword: formValues.confirmPassword
        ? formValues.confirmPassword === formValues.password
          ? ""
          : t("profile.Change Password.Passwords don't match")
        : t("profile.Change Password.confirm Password"),
    };

    setFormErrors(errors);
    return Object.keys(errors).every((key) => !errors[key]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      // console.log("Form submitted successfully", formValues);
      const body = {
        userId: userId.toString(),
        oldPassword: formValues?.oldPassword,
        newPassword : formValues?.password
      }
      const response = await ChangePassword(body);
      if (response?.data?.isSuccess) {
        toast.success(response?.data?.message);
        navigate("/Home/1");
      }
      else {
        toast.error(response?.data?.message)
      }
    }
  };

  useEffect(() => {
    const isValid =
      Object.values(formValues).every((value) => value) && validate();
    setIsFormValid(isValid);
  }, [formValues]);

  return (
    <div className="form-container update-password">
      <div className="update-password-wrapper">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="password"
              name="oldPassword"
              placeholder={t("profile.Change Password.Old Password")}
              className={`form-control ${
                formErrors.oldPassword ? "error" : ""
              }`}
              value={formValues.oldPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {formErrors.oldPassword && (
              <p className="error-message">{formErrors.oldPassword}</p>
            )}
          </div>

          <div className="form-group">
            <input
              type="password"
              name="password"
              placeholder={t("profile.Change Password.New Password")}
              className={`form-control ${formErrors.password ? "error" : ""}`}
              value={formValues.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {formErrors.password && (
              <p className="error-message">{formErrors.password}</p>
            )}
          </div>

          <div className="form-group">
            <input
              type="password"
              name="confirmPassword"
              placeholder={t("profile.Change Password.Confirm Password")}
              className={`form-control ${
                formErrors.confirmPassword ? "error" : ""
              }`}
              value={formValues.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {formErrors.confirmPassword && (
              <p className="error-message">{formErrors.confirmPassword}</p>
            )}
          </div>

          <button type="submit" className="submit-btn" disabled={!isFormValid}>
            {t("profile.Change Password.Update")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordUpdate;
