import React from "react";
// import userImage from "../../../assets/Evening.jpeg";
import userImage from "../../../assets/laula.jpeg"
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import CardImg from "../../../assets/Evening1.jpeg";
import Auth from "./Auth";
import { useTranslation } from "react-i18next";
const LockModal = ({ showLockModal }) => {
  const { t, i18n } = useTranslation();

  const listData = [
    {
      title: t("lockModal.relief.0"),
      description: t("lockModal.relief description.0"),
      icon: <IoCheckmarkCircleSharp />,
    },
    {
      title: t("lockModal.relief.1"),
      description: t("lockModal.relief description.1"),
      icon: <IoCheckmarkCircleSharp />,
    },
    {
      title: t("lockModal.relief.2"),
      description: t("lockModal.relief description.2"),
      icon: <IoCheckmarkCircleSharp />,
    },
  ];
  return (
    <>
      <section className="lock-modal">
        <RxCross2 className="cross-icon" onClick={showLockModal} />
        <div className="lock-modal-wrapper">
          <div>
            <div className="lock-modal-content-left">
              <div className="lock-modal-left-wrapper">
                <div className="lock-modal-img-content">
                  <div className="elipse elipse-one"></div>
                  <div className="elipse elipse-two"></div>
                  <div className="lock-modal-img-inner-content">
                    <img src={CardImg} alt="" />
                  </div>
                  <div className="lock-modal-img-content-wrapper">
                    <h6>{t("lockModal.album")}</h6>
                    <h5>{t("lockModal.workflow")}</h5>
                    {/* <button>{t("lockModal.curated")}</button> */}
                    <div className="lock-modal-user-content">
                      <img src={userImage} alt="userImage" />
                      <div className="user-inner-content">
                        <h5>Loula M.</h5>
                        <p>Author & Meditation Guide</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lock-modal-main-content-wrapper">
              <div className="lock-modal-left-title">
                <h2>{t("lockModal.offer")}</h2>
              </div>
              {listData?.map((data) => {
                return (
                  <>
                    <div className="lock-modal-left-list">
                      <div className="lock-modal-left-list-content">
                        {data?.icon}
                        <h4>{data?.title}</h4>
                      </div>
                      <div className="lock-modal-left-list-content">
                        <p>{data?.description}</p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="lock-modal-content-right">
            <Auth handleModal={showLockModal}/>
          </div>
        </div>
      </section>
    </>
  );
};

export default LockModal;
