// import React, { useContext } from "react";
// // import { AudioContext } from './AudioContext';
// import "../style/audioControlDetail.css"; // Default styles
// import { useAudio } from "../AudioContext/AudioContext";
// import SvgComponent from "./SvgComponent";
// import { useNavigate } from "react-router-dom";

// const AudioControlDetail = () => {
//   const navigate = useNavigate();
//   const {
//     handleStop,
//     AudioPisPlaying,
//     AudioPcurrentTime,
//     AudioPduration,
//     AudioPplayPause,
//     AudioPstop,
//     AudioPforward,
//     AudioPbackward,
//     AudioPchangeCurrentTime,
//     setHandleStopButton
//   } = useAudio();
//   const handleProgressChange = (event) => {
//     const newTime = event.target.value;
//     AudioPchangeCurrentTime(newTime);
//   };
//   const calculatePercentage = () => {
//     return AudioPduration ? (AudioPcurrentTime / AudioPduration) * 100 : 0;
//   };
//   return (
//     <div className={`audio-controls-detail`}>
//       <div className="line-buttons-detail">
//         <div className="topbar-detail">
//           <button className="control-button" onClick={AudioPbackward}>
//             <SvgComponent name="previousVolume" />
//           </button>

//           <button
//             className="control-button pause-play-button"
//             onClick={AudioPplayPause}
//           >
//             {AudioPisPlaying ? (
//               <SvgComponent name="pauseVolume" />
//             ) : (
//               <SvgComponent name="playVolume" />
//             )}
//           </button>

//           <button className="control-button" onClick={AudioPforward}>
//             <SvgComponent name="nextVolume" />
//           </button>
//         </div>

//         <button
//           className="stop-button-detail control-button"
//           onClick={() => {
//             AudioPstop();
//             handleStop();
//             setHandleStopButton(true);
//             navigate("/");
//           }}
//         >
//           <SvgComponent name="hideTrack" />
//         </button>
//       </div>
//       <div className="progress-bar-detail">
//         <span className="time-display-detail">
//           {Math.floor(AudioPcurrentTime / 60)}:
//           {Math.floor(AudioPcurrentTime % 60)
//             .toString()
//             .padStart(2, "0")}
//         </span>
//         <input
//           type="range"
//           value={AudioPcurrentTime}
//           max={AudioPduration}
//           onChange={handleProgressChange}
//           className="progress-input-detail"
//           style={{
//             background: `linear-gradient(to right, #00a6d6 ${calculatePercentage()}%, #fff ${calculatePercentage()}%)`,
//           }}
//         />
//         <span className="time-display-detail">
//           {Math.floor(AudioPduration / 60)}:
//           {Math.floor(AudioPduration % 60)
//             .toString()
//             .padStart(2, "0")}
//         </span>
//       </div>
//     </div>
//   );
// };

// export default AudioControlDetail;

import React from "react";
import "../style/audioControlDetail.css"; // Default styles
import { useAudio } from "../AudioContext/AudioContext";
import SvgComponent from "./SvgComponent";
import { useNavigate } from "react-router-dom";

const AudioControlDetail = () => {
  const navigate = useNavigate();
  const {
    handleStop,
    AudioPisPlaying,
    AudioPcurrentTime,
    AudioPduration,
    AudioPplayPause,
    AudioPstop,
    AudioPforward,
    AudioPbackward,
    AudioPchangeCurrentTime,
    setHandleStopButton,
  } = useAudio();

  const handleProgressChange = (event) => {
    const newTime = event.target.value;
    AudioPchangeCurrentTime(newTime);
  };

  const calculatePercentage = () => {
    return AudioPduration ? (AudioPcurrentTime / AudioPduration) * 100 : 0;
  };

  return (
    <div className="audio-controls-detail">
      <div className="line-buttons-detail">
        <div className="topbar-detail">
          <button className="control-button" onClick={AudioPbackward}>
            <SvgComponent name="previousVolume" />
          </button>

          <button
            className="control-button pause-play-button"
            onClick={AudioPplayPause}
          >
            {AudioPisPlaying ? (
              <SvgComponent name="pauseVolume" />
            ) : (
              <SvgComponent name="playVolume" />
            )}
          </button>

          <button className="control-button" onClick={AudioPforward}>
            <SvgComponent name="nextVolume" />
          </button>
        </div>

        <button
          className="stop-button-detail control-button"
          onClick={() => {
            AudioPstop();
            handleStop();
            setHandleStopButton(true);
            navigate("/");
          }}
        >
          <SvgComponent name="hideTrack" />
        </button>
      </div>
      <div className="progress-bar-detail">
        <span className="time-display-detail">
          {Math.floor(AudioPcurrentTime / 60)}:
          {Math.floor(AudioPcurrentTime % 60)
            .toString()
            .padStart(2, "0")}
        </span>
        <input
          type="range"
          value={AudioPcurrentTime}
          max={AudioPduration}
          onChange={handleProgressChange}
          className="progress-input-detail"
          style={{
            background: `linear-gradient(to right, #00a6d6 ${calculatePercentage()}%, #fff ${calculatePercentage()}%)`,
          }}
        />
        <span className="time-display-detail">
          {Math.floor(AudioPduration / 60)}:
          {Math.floor(AudioPduration % 60)
            .toString()
            .padStart(2, "0")}
        </span>
      </div>
    </div>
  );
};

export default AudioControlDetail;
