import React, { useEffect, useState } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
// import client from "@react-oauth/google";

const GoogleAuth = ({ handleModal, render }) => {
  const [userData, setUserData] = useState();
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
  useEffect(() => {
    if (userData?.credential) {
      credentials(userData);
    }
  }, [userData]);

  const credentials = async (userData) => {
    try {
      // console.log("User Data::", userData);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/google-auth`,
        {
          userData,
          headers: {
            Authorization: `Bearer ${userData.clientId}`,
            Accept: "application/json",
          },
        }
      );
      // console.log("response.data", response?.data?.data);
      if (response?.data?.data) {
        localStorage.setItem("token", response?.data?.data);
        sessionStorage.removeItem("menu");
        handleModal();
      }
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  //   const fun = async () => {
  //     try {
  //       const ticket = await client.verifyIdToken({
  //         idToken: userData.credential,
  //         audience: userData.clientId,
  //       });
  //       const payload = ticket.getPayload();
  //       const userid = payload["sub"];
  //       console.log({ payload });
  //     } catch (err) {
  //       console.log({ err });
  //     }
  //   };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        style={{
          display: "none",
        }}
        onSuccess={(codeResponse) => {
          // console.log(codeResponse);
          setUserData(codeResponse);
        }}
        onError={() => {
          // console.log("Login Failed");
        }}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className="custom-google-button"
            style={{
              padding: "10px",
              backgroundColor: "#4285F4",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Custom Google Login Button
          </button>
        )}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
