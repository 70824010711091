import React, { useState, useEffect } from "react";
import Card from "./Card";
import "../style/cardSlider.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import LockModal from "./authModal/LockModal";

const CardSlider = ({
  parentTitle,
  title,
  data,
  slidesPerShow,
  cardHeight,
  cardSpacing,
  category_id = null,
  tracks,
}) => {
  // console.log({cardHeight})
  const [showLockModal, setLockShowModal] = useState(false);
  const mergedData = tracks ? [...data, ...tracks] : data;
  const handleLockModal = () => {
    setLockShowModal(!showLockModal);
    if (showLockModal) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  // console.log("eewfbebfjefjl", category_id);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeButton, setActiveButton] = useState("next");
  const [translateValue, setTranslateValue] = useState("");
  const [translateValueTopSlides, setTranslateValueTopSlides] = useState("");

  const nextSlide = () => {
    // console.log(
    //   "data?.length - slidesPerShow",
    //   mergedData?.length - slidesPerShow,
    //   currentIndex
    // );
    if (currentIndex < mergedData?.length) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setActiveButton("both");
    }
    if (currentIndex >= mergedData?.length - slidesPerShow + 1) {
      setActiveButton("prev");
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setActiveButton(currentIndex - 1 === 0 ? "next" : "both");
    }
    if (currentIndex <= mergedData?.length - slidesPerShow) {
      setActiveButton("both");
    }
  };
  const formattedTitle = title?.toLowerCase()?.replace(/\s+/g, "-");
  // console.log("mergedData::", mergedData);
  useEffect(() => {
    const updateTranslateValues = () => {
      if (window.innerWidth < 377) {
        setTranslateValue(
          `calc(-${currentIndex * 40}% - ${currentIndex * 19.5}px)`
        );
        setTranslateValueTopSlides(
          `calc(-${currentIndex * 68}% - ${currentIndex * 18}px)`
        );
      } else if (window.innerWidth < 492) {
        setTranslateValue(
          `calc(-${currentIndex * 40}% - ${currentIndex * 21}px)`
        );
        setTranslateValueTopSlides(
          `calc(-${currentIndex * 68}% - ${currentIndex * 19}px)`
        );
      } else if (window.innerWidth < 700) {
        setTranslateValue(
          `calc(-${currentIndex * 39.8}% - ${currentIndex * 25}px)`
        );
        setTranslateValueTopSlides(
          `calc(-${currentIndex * 34}% - ${currentIndex * 18}px)`
        );
      } else if (window.innerWidth < 562) {
        setTranslateValue(
          `calc(-${currentIndex * 39}% - ${currentIndex * 20}px)`
        );
        setTranslateValueTopSlides(
          `calc(-${currentIndex * 30}% - ${currentIndex * 18}px)`
        );
      } else if (window.innerWidth < 768) {
        setTranslateValue(
          `calc(-${currentIndex * 40}% - ${currentIndex * 26}px)`
        );
        setTranslateValueTopSlides(
          `calc(-${currentIndex * 34}% - ${currentIndex * 18}px)`
        );
      } else {
        setTranslateValue(
          `calc(-${currentIndex * 16}% - ${currentIndex * 40}px)`
        );
        setTranslateValueTopSlides(
          `calc(-${currentIndex * 26}% - ${currentIndex * 18.6}px)`
        );
      }
    };

    updateTranslateValues();
    window.addEventListener("resize", updateTranslateValues);

    return () => {
      window.removeEventListener("resize", updateTranslateValues);
    };
  }, [currentIndex]);
  return (
    <>
      {mergedData?.length > 0 ? (
        <>
          <div
            className="slider-container"
            style={{ paddingBottom: cardHeight ? "36px" : "1rem" }}
          >
            <div className="slider_header">
              <h2 style={{ padding: cardSpacing ? "2rem 0 1rem 0" : "" }}>
                {title}
              </h2>
              <div
                className="slides_btns"
                style={{ padding: cardSpacing ? "2rem 2.5rem 1rem 0" : "" }}
              >
                <div className="slides_btns_wrapper">
                  <button
                    className={`prev ${
                      activeButton === "prev" || activeButton === "both"
                        ? "active"
                        : ""
                    }`}
                    onClick={prevSlide}
                    disabled={currentIndex === 0}
                  >
                    <IoIosArrowBack />
                  </button>
                  <button
                    className={`next ${
                      activeButton === "next" || activeButton === "both"
                        ? "active"
                        : ""
                    }`}
                    onClick={nextSlide}
                    disabled={
                      currentIndex > mergedData?.length + 1 - slidesPerShow
                    }
                  >
                    <IoIosArrowForward />
                  </button>
                </div>
                {title != "Favourites" && title != "Featured" && (
                  <div className="see_all_btn">
                    <Link
                      to={`/see-all/${formattedTitle}/${category_id}`}
                      state={{ parentTitle: parentTitle }}
                    >
                      See All <IoIosArrowForward />
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="slider">
              <div
                className="slides"
                // style={{
                //   transform: `translateX(-${
                //     currentIndex * (100 / slidesPerShow)
                //   }%)`,
                // }}
                style={{
                  transform: cardHeight
                    ? `translateX(${translateValueTopSlides})`
                    : `translateX(${translateValue})`,
                }}
              >
                {mergedData?.map((item, index) => {
                  // console.log("item::", item);
                  return (
                    <div
                      className="slide"
                      key={index}
                      style={{ minWidth: `calc(104% / ${slidesPerShow})` }}
                    >
                      {/* {title === "Favourites" && console.log("yeeeee==========:::::    ", item)} */}
                      {title === "Favourites" ? (
                        <Card
                          data={item?.track}
                          cardHeight={cardHeight}
                          handleLockModal={handleLockModal}
                          category={title}
                        />
                      ) : (
                        <Card
                          data={item}
                          cardHeight={cardHeight}
                          handleLockModal={handleLockModal}
                          category={title}
                        />
                      )}
                    </div>
                  );
                })}
                {/* {tracks?.length > 0 && */}
              </div>
              {showLockModal && (
                <div
                  className={`auth-modal-overlay ${
                    showLockModal ? "user-modal-overlay" : ""
                  }`}
                >
                  <LockModal showLockModal={handleLockModal} />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CardSlider;
