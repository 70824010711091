import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout.jsx";
import Detail from "../components/seeDetails/Detail.jsx";
import { useParams } from "react-router-dom";
import { sleepStories } from "../Data/data.js";
import { globalApiCalling } from "../global/index.js";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const SeeDeatils = () => {
  const { title, id } = useParams();
  const [Loading, setLoading] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [albums, setAlbums] = useState();
  const location = useLocation();
  const { parentTitle } = location.state || {};
  // const []
  // console.log({ id });
  const getCategoryAlbums = async () => {
    try {
      const response = await globalApiCalling(
        setLoading,
        "GET",
        `${process.env.REACT_APP_BASE_URL}category/${id}?limit=10000`,
        null
      );
      if (response?.data?.isSuccess === false) {
        // console.log(response?.data?.message);
        toast.error(response?.data?.message);
        return;
      } else {
        // setRender(true);
        // console.log(response?.data?.data, "ifeiufeufiifeiufeufi");

        if (response?.data?.data) {
          // setData([response?.data?.data]);
          // setSubCategoriesData(response?.data?.data?.rows);
          if (response?.data?.data?.categoryData?.tracks?.length > 0) {
            setAlbums([
              ...response?.data?.data?.categoryData?.albums,
              ...response?.data?.data?.categoryData?.tracks,
            ]);
          } else {
            setAlbums(response?.data?.data?.categoryData?.albums);
          }
          setIsLoading(false);
          // console.log("response?.data?.data", response?.data?.data);
          // toast.success(response?.data?.message);
        }
        // handleModal();
        return;
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getCategoryAlbums();
  }, [id]);
  // const formattedTitle = title?.replace(/-/g, " ");
  const formattedTitle = title
  ?.replace(/-/g, " ")
  .replace(/\b\w/g, char => char.toUpperCase());

  // console.log("sleepStories[0]", sleepStories[0]);
  return (
    <Layout parentTitle={parentTitle} title={formattedTitle}>
      {isLoading ? (
        <div style={{ margin: "auto" }} className="loader"></div>
      ) : (
        <Detail  title={formattedTitle} data={albums} />
      )}
    </Layout>
  );
};

export default SeeDeatils;
