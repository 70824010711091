import React, { useContext, useState, useEffect } from "react";
import Layout from "../layout/Layout";
import AudioPlayer from "react-h5-audio-player";
import { CiHeart } from "react-icons/ci";
import { IoShareOutline } from "react-icons/io5";
import SvgComponent from "../common/SvgComponent";
import { useNavigate, useLocation } from "react-router-dom";
import ShareModal from "../common/ShareModal";
import { IoIosArrowDown } from "react-icons/io";
import { RWebShare } from "react-web-share";
import { useAudio } from "../AudioContext/AudioContext";
import { jwtDecode } from "jwt-decode";
import {
  getFavouritesById,
  getSingleTrack,
  toggleFavouriteTrack,
} from "../../Api/helper";
import "../../components/style/audioPlayer.css";
import AudioControls from "../common/AudioControls";
import AudioControlDetail from "../common/AudioControlDetail";

const AlbumMusic = () => {
  const {
    albumDetails,
    authorDetails,
    audioDetails,
    setIsPlaying,
    handlePlay,
    handleStop,
    currentTime,
    setCurrentTime,
    audioPlayerRefTrack,
    handleStopButton,
    setHandleStopButton,
    favouriteTrackIds,
    setFavouriteTrackIds,
    favourites,
    user_id,
    AudioPchangeAudioSource,
    AudioPstop,
    AudioPaudioSource
  } = useAudio();
  const [clickedHearts, setClickedHearts] = useState(false);
  const [showLockModal, setLockShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [currentAudioTime, setCurrentAudioTime] = useState();
  const [audioSrc, setAudioSrc] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  // console.log("in album music:    ", authorDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const id = parseInt(pathSegments[pathSegments.length - 1], 10);
  const audioData = location?.state?.audioDetails;
  // let currentAudioTime = location?.state?.currentTime;
  useEffect(() => {
    const getData = async () => {
      if (!audioDetails?.source) {
        const result = await getSingleTrack(id);
        setAudioSrc(result?.data?.data?.track);
      }
    };
    getData();
  }, []);
  useEffect(() => {
    setCurrentAudioTime(location?.state?.currentTime);
  }, []);
  // console.log({ audioDetails });
  const matchingTrack = favourites?.find((fav) => fav?.track?.id === id);
  // console.log("check music id::: ", matchingTrack.track.author.name);

  const handleLockModal = () => {
    setLockShowModal(!showLockModal);
  };

  // const token = localStorage.getItem("token");
  // const decoded = jwtDecode(token);
  // const user_id = decoded?.user?.id;

  const handleHeartClick = async () => {
    setClickedHearts(!clickedHearts);

    const body = {
      track_id: audioDetails?.id,
      user_id: user_id,
    };
    // console.log("body:::  ", body);
    const result = await toggleFavouriteTrack(body);
    if (!result?.data?.isSuccess) {
      console.log("error is as:  ", result?.data?.message);
    } else {
      const updatedFavouriteTrackIds = favouriteTrackIds.includes(
        audioDetails?.id
      )
        ? favouriteTrackIds.filter((id) => id !== audioDetails?.id)
        : [...favouriteTrackIds, audioDetails?.id];
      setFavouriteTrackIds(updatedFavouriteTrackIds);
    }
  };
  const handleShareModal = () => {
    setShowShareModal(!showShareModal);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (handleStopButton) {
        setIsPlaying(false);
      } else {
        setIsPlaying(true);
      }
    };
  }, [setIsPlaying, handleStopButton]);

  useEffect(() => {
    if (favouriteTrackIds.includes(audioDetails?.id)) {
      // console.log("list", favouriteTrackIds, "1234567890   ", audioDetails);
      // setHeartStyle("rgb(255, 89, 89)");
      // setClickedHearts(!clickedHearts);
    } else {
      setClickedHearts(!clickedHearts);
    }
  }, [favouriteTrackIds, audioDetails]);

  // const formatDuration = (duration) => {
  //   if (duration < 60) {
  //     return `${duration}m`;
  //   } else {
  //     const hours = Math.floor(duration / 60);
  //     const minutes = duration % 60;
  //     return minutes > 0 ? `${hours}hours ${minutes}minutes` : `${hours}h`;
  //   }
  // };
  const formatDuration = (duration) => {
    if (duration < 60) {
      return `${duration}s`;
    } else if (duration < 3600) {
      // less than 1 hour
      const minutes = Math.floor(duration / 60);
      const seconds = duration % 60;
      return seconds > 0 ? `${minutes}m ${seconds}s` : `${minutes}m`;
    } else {
      // 1 hour or more
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = duration % 60;
      let result = `${hours}h`;
      if (minutes > 0) {
        result += ` ${minutes}m`;
      }
      if (seconds > 0) {
        result += ` ${seconds}s`;
      }
      return result;
    }
  };

  return (
    <Layout hideHader={false}>
      <section className="album-deatils-main-container music-details-container">
        <IoIosArrowDown
          className="below-icon"
          onClick={() => navigate("/Home/1")}
        />
        <div className="details-music-container music-details-wrapper-content section-spacing ">
          <div className="album-details-music-inner-content-wrapper">
            <div className="details-music-author">
              <div className="music-author-info">
                <h1>
                  {albumDetails?.albumTitle ||
                    matchingTrack?.track?.album?.title ||
                    audioDetails?.name ||
                    audioSrc?.title}
                </h1>
                <p>
                  {formatDuration(audioDetails?.duration || audioSrc?.duration)}
                </p>
              </div>
            </div>
            <div className="music-details-author-content">
              {(authorDetails?.name || matchingTrack?.track?.author?.name) && (
                <div className="music-details-inner-content">
                  <h3 style={{ fontSize: "18px", fontWeight: 700 }}>
                    NARRATOR
                  </h3>
                  <p>
                    {authorDetails?.name || matchingTrack?.track?.author?.name}
                  </p>
                </div>
              )}
              {(authorDetails?.name || matchingTrack?.track?.author?.name) && (
                <div className="music-details-inner-content">
                  <h3 style={{ fontSize: "18px", fontWeight: 700 }}>AUTHOR</h3>
                  <p>
                    {authorDetails?.name || matchingTrack?.track?.author?.name}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="album-music-audio">
            <div className="music-player-icons">
              {/* <div className="music-player-icons-wrap"> */}
              <CiHeart
                onClick={handleHeartClick}
                style={
                  favouriteTrackIds?.includes(audioDetails?.id)
                    ? {
                        background: "#d94c4c",
                      }
                    : {}
                }
              />
              {/* </div> */}
              <div className="music-player-share-icon-wrap">
                {!isMobile && <IoShareOutline onClick={handleShareModal} />}
                {isMobile && (
                  <RWebShare
                    data={{
                      text: "Like humans, Plenum make friends for life",
                      url: "https://plenumapp.com" + location.pathname,
                      title: "Plenum Share",
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <IoShareOutline />
                  </RWebShare>
                )}
              </div>
            </div>

            <div className="audio-player-wrapper music-player-audio music-audio-player-icon-wrapper">
              {/* <AudioPlayer
                autoPlay
                loop
                ref={audioPlayerRefTrack}
                src={audioDetails?.source || audioSrc?.file_path}
                // onPlay={() =>
                //   (audioPlayerRefTrack.current.audio.current.currentTime =
                //     currentTime + 1)
                // }
                onPlay={() =>
                  (audioPlayerRefTrack.current.audio.current.currentTime && (audioPlayerRefTrack.current.audio.current.currentTime =
                    currentAudioTime + 1 ))
                }
                onListen={(currentTime) => {
                  setCurrentTime(currentTime);  
                }}
                onPause={() =>
                  setCurrentAudioTime(
                    audioPlayerRefTrack?.current?.audio?.current?.currentTime
                  ) && audioPlayerRefTrack?.current?.pause
                }
                progressJumpSteps={{ backward: 15000, forward: 15000 }}
                customIcons={{
                  play: <SvgComponent name="playVolume" />,
                  pause: <SvgComponent name="pauseVolume" />,
                  rewind: <SvgComponent name="previousVolume" />,
                  forward: <SvgComponent name="nextVolume" />,
                }}
              /> */}
              {/* <div
                className="hide-volum-track"
                onClick={() => {
                  // handleStop();
                  setHandleStopButton(true);
                  navigate("/Home/1");
                }}
              >
                <SvgComponent name="hideTrack" />
              </div> */}
            </div>
            {audioDetails?.source ? (
              <AudioControlDetail />
            ) : (
              <>
                {!AudioPaudioSource && AudioPchangeAudioSource(audioSrc?.file_path)}
                <AudioControlDetail />
              </>
            )}
          </div>
        </div>
        {showShareModal && <ShareModal shareModal={handleShareModal} />}
      </section>
    </Layout>
  );
};

export default AlbumMusic;
