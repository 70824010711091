import DailyCalm from "../assets/daily_calm.jpeg";
import Afternoon from "../assets/afterReset-1.jpeg";
import Afternoon_2 from "../assets/afterReset-2.jpeg";
import Afternoon_3 from "../assets/afterReset-3.jpeg";
import Music from "../assets/music-1.png";
import Music_2 from "../assets/afterReset-2.jpeg";
import Music_3 from "../assets/music-3.jpeg";
import Soundscapes from "../assets/soundscapes.jpeg";
import Soundscapes_2 from "../assets/soundscapes-2.jpeg";
import Soundscapes_3 from "../assets/soundscapes-3.jpeg";
import SleepStories from "../assets/sleepstorie.jpeg";
import SleepStories_2 from "../assets/sleepstorie-2.png";
import SleepStories_3 from "../assets/sleepstorie-3.jpeg";
import TodayDailie from "../assets/todayDailie.jpeg";
import TodayDailie_2 from "../assets/todayDailie-2.jpeg";
import TodayDailie_3 from "../assets/todayDailie-3.jpeg";

export const todayDailie = [
  {
    id: 1,
    image: DailyCalm,
    title: "Daily Calm",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 2,
    image: TodayDailie,
    title: "Daily Jay",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 3,
    image: TodayDailie_2,
    title: "Daily Move",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 4,
    image: TodayDailie_3,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },

  {
    id: 5,
    image: DailyCalm,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: TodayDailie,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 7,
    image: TodayDailie_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
];
export const afterReset = [
  {
    id: 1,
    image: Afternoon,
    title: "Daily Calm",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 2,
    image: Afternoon_2,
    title: "Daily Jay",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 3,
    image: Afternoon_3,
    title: "Daily Move",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 4,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },

  {
    id: 5,
    image: Afternoon_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Afternoon_3,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Afternoon_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 7,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 8,
    image: Afternoon_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
];
export const musicData = [
  {
    id: 1,
    image: Music,
    title: "Daily Calm",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 2,
    image: Music_2,
    title: "Daily Jay",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 3,
    image: Music_3,
    title: "Daily Move",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 4,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },

  {
    id: 5,
    image: SleepStories,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: SleepStories_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Afternoon_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 7,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 8,
    image: Afternoon_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
];
export const soundScapes = [
  {
    id: 1,
    image: Soundscapes,
    title: "Daily Calm",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 2,
    image: Soundscapes_2,
    title: "Daily Jay",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 3,
    image: Soundscapes_3,
    title: "Daily Move",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 4,
    image: SleepStories,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },

  {
    id: 5,
    image: Afternoon_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Afternoon_3,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Soundscapes,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Soundscapes_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 7,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 8,
    image: Afternoon_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
];
export const sleepStories = [
  {
    id: 1,
    image: SleepStories,
    title: "Daily Calm",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 2,
    image: SleepStories_2,
    title: "Daily Jay",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 3,
    image: SleepStories_3,
    title: "Daily Move",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 4,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },

  {
    id: 5,
    image: SleepStories,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: SleepStories_3,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Soundscapes,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Soundscapes_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 6,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 7,
    image: Afternoon,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
  {
    id: 8,
    image: Afternoon_2,
    title: "Daily Trip",
    category: "Meditation",
    subCategory: "Tamara Levitt",
    date: "May 20",
    dateText: "Bubbles",
    hide: false,
    width: 200,
  },
];
