import React, { useEffect, useState } from "react";
import { CiLock } from "react-icons/ci";
import "../style/card.css";
import { useNavigate } from "react-router-dom";
import Auth from "./authModal/Auth";
import { useAudio } from "../AudioContext/AudioContext";
import AlbumDetail from "../albumDetail/AlbumDetail";
import { jwtDecode } from "jwt-decode";
import { useParams } from "react-router-dom";

const Card = ({ data, cardHeight, handleLockModal, category, reSize }) => {
  const {
    pauseAudio,
    handlePlay,
    handleStop,
    setAudioDetails,
    albumDetails,
    setAlbumDetails,
    authorDetails,
    setAuthorDetails,
    favouriteTrackIds,
    setFavouriteTrackIds,
    setHandleStopButton,
    setCurrentTime,
    user_id,
    AudioPchangeAudioSource
  } = useAudio();
  // console.log({cardHeight})
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  // const token = localStorage.getItem("token");
  // if (token) {
  //   const dataCheck = jwtDecode(token);
  //   console.log("qwertyuiop11111111    ", dataCheck);
  // }
  const { title } = useParams();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleModal = () => {
    setShowModal(!showModal);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    let tokenData;
    if (token) {
      tokenData = jwtDecode(token);
      setSubscriptionStatus(tokenData?.user?.subscription_status);
    }
  }, [])
  const handleClick = () => {
    const token = localStorage.getItem("token");
    let tokenData;
    if (token) {
      tokenData = jwtDecode(token);
      setSubscriptionStatus(tokenData?.user?.subscription_status);
    }
    if (tokenData?.user?.subscription_status == "active") {
      
      if (data?.file_path) {
        pauseAudio();
        setCurrentTime(0);
        handlePlay();
        setAudioDetails({
          id: data?.id,
          source: data?.file_path,
          name: data?.title,
          // album: albumDetails?.albumTitle,
          album: data?.album?.title,
          image: data?.image,
          duration: data?.duration,
        });
        AudioPchangeAudioSource(data?.file_path);
        setHandleStopButton(false);
        setAuthorDetails({
          name: data?.author?.name,
        });
      }
      else {
        
        navigate(`/details/${data.id}`);
      }
    } else if (data?.is_paid && token) {
      navigate("/new-member-offer");
      // } else if (data?.is_paid && !token) {
    } else if (!token) {
      handleLockModal();
    } else if (data?.file_path) {
      pauseAudio();
      setCurrentTime(0);
      handlePlay();
      setAudioDetails({
        id: data?.id,
        source: data?.file_path,
        name: data?.title,
        // album: albumDetails?.albumTitle,
        album: data?.album?.title,
        image: data?.image,
        duration: data?.duration,
      });
      AudioPchangeAudioSource(data?.file_path);
      setHandleStopButton(false);
      setAuthorDetails({
        name: data?.author?.name,
      });
    } else {
      navigate(`/details/${data.id}`);
    }
  };
  const formattedTitle = title
  ?.replace(/-/g, " ")
  .replace(/\b\w/g, char => char.toUpperCase());
  return (
    <>
      <section className="card" onClick={handleClick}>
        <div className="card_wrapper">
          <div className={`${cardHeight ? cardHeight : "card-img"} card-img`}>
            <img
              src={data?.image}
              alt=""
              // style={cardHeight && { height: cardHeight }}
            />
            {subscriptionStatus == "active" ? "" : (data.is_paid ? <CiLock onClick={handleClick} /> : "")}
          </div>
          <div
            className="card_content"
            style={{ textAlign: reSize ? "left" : "inherit" }}
          >
            <h5>{data?.title}</h5>
            {/* <div className="card_inner_content">
              <p>{category}</p>
              {data?.author?.name && <p className="card_circle">{data?.author?.name}</p>}
            </div> */}
            <div className="card_inner_content">
              <p>
                {category || formattedTitle}
                {data?.author?.name && (
                  <span style={{ marginLeft: "4px", marginRight: "1px" }}>
                    {" "}
                    •{" "}
                  </span>
                )}
                {data?.author?.name && (
                  <span className="card_circle">{data?.author?.name}</span>
                )}
              </p>
            </div>
            <div className="card_inner_content">
              <p>{data?.date}</p>
              {data?.dateText && (
                <p className="card_circle">{data?.dateText}</p>
              )}
            </div>
          </div>
        </div>
        {showModal && (
          <div
            className={`auth-modal-overlay ${
              showModal ? "user-modal-overlay" : ""
            }`}
          >
            <Auth handleModal={handleModal} />
          </div>
        )}
      </section>
    </>
  );
};

export default Card;
